import { getProviderId } from 'src/utils/local-storage';
import { IReqCreateLocationSatuSehat, IResLocationSatuSehat } from 'src/@types/location-satu-sehat';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/ihs-api/location';

const locationSatuSehatApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    getLocationIhsById: build.query<IResLocationSatuSehat, string>({
      query: (ihsLocationId) => ({
        url: `${prefix}/${getProviderId()}/${ihsLocationId}`,
        method: 'GET',
      }),
    }),
    postLocation: build.mutation<IResLocationSatuSehat, IReqCreateLocationSatuSehat>({
      query: (data) => ({
        url: `${prefix}/${getProviderId()}`,
        method: 'POST',
        data,
      }),
    }),
    updateLocation: build.mutation<IResLocationSatuSehat, IReqCreateLocationSatuSehat>({
      query: (data) => ({
        url: `${prefix}/${getProviderId()}/${data.ihsLocationId}`,
        method: 'PUT',
        data,
      }),
    }),
  }),
});

export const { useGetLocationIhsByIdQuery, usePostLocationMutation, useUpdateLocationMutation } =
  locationSatuSehatApi;
