import { getProviderId } from 'src/utils/local-storage';
import {
  IInventoryAdjustment,
  IInventoryMaterialReceipt,
  IPostSaveInventoryAdjustment,
  IPostValidateInventoryAdjustment,
  IReqInventoryMaterialReceipt,
} from 'src/@types/inventory';
import { IPagination, IReqGlobalRsql } from 'src/@types/base';
import { normalizeParams } from 'src/utils/convertFilterString';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/inventory';
const prefixMaterialReceipt = '/services/inventory/api/kemedis/inventory';

const intentoryApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    getInventoryAdjustments: build.query<
      IPagination<IInventoryAdjustment>,
      Partial<IReqGlobalRsql>
    >({
      query: (reqRsql) => ({
        url: `${prefix}/adjustments/provider/${getProviderId()}`,
        method: 'GET',
        params: normalizeParams(reqRsql),
      }),
    }),
    validateInventoryAdjustment: build.mutation<
      IInventoryAdjustment,
      IPostValidateInventoryAdjustment
    >({
      query: (data) => ({
        url: `${prefix}/adjustments/validate/${data.id}`,
        method: 'POST',
        data,
      }),
    }),
    createInventoryAdjustment: build.mutation<IInventoryAdjustment, IPostSaveInventoryAdjustment>({
      query: (data) => ({
        url: `${prefix}/adjustments`,
        method: 'POST',
        data,
      }),
    }),
    updateInventoryAdjustment: build.mutation<IInventoryAdjustment, IPostSaveInventoryAdjustment>({
      query: (data) => ({
        url: `${prefix}/adjustments/${data.id}`,
        method: 'PUT',
        data,
      }),
    }),
    getInventoryAdjustmentById: build.query<IInventoryAdjustment, string>({
      query: (id) => ({
        url: `${prefix}/adjustments/${id}`,
        method: 'GET',
      }),
    }),
    getInventoryMaterialReceipts: build.query<
      IPagination<IInventoryMaterialReceipt>,
      Partial<IReqGlobalRsql>
    >({
      query: (reqRsql) => ({
        url: `${prefixMaterialReceipt}/material-receipts/provider/${getProviderId()}`,
        method: 'GET',
        params: normalizeParams(reqRsql),
      }),
    }),
    createInventoryMaterialReceipt: build.mutation<
      IInventoryMaterialReceipt,
      IReqInventoryMaterialReceipt
    >({
      query: (data) => ({
        url: `${prefixMaterialReceipt}/material-receipts`,
        method: 'POST',
        data,
      }),
    }),
  }),
});

intentoryApi.enhanceEndpoints({
  addTagTypes: ['InventoryAdjustment', 'Medicine', 'MedicalDevice', 'InventoryMaterialReceipt'],
  endpoints: {
    getInventoryAdjustments: {
      providesTags: (result) =>
        result
          ? [
              ...result.content.map(({ id }) => ({ type: 'InventoryAdjustment' as const, id })),
              'InventoryAdjustment',
            ]
          : ['InventoryAdjustment'],
    },
    createInventoryAdjustment: {
      invalidatesTags(result, error, { id }) {
        return [{ type: 'InventoryAdjustment', id }];
      },
    },
    updateInventoryAdjustment: {
      invalidatesTags(result, error, { id }) {
        return [{ type: 'InventoryAdjustment', id }];
      },
    },
    validateInventoryAdjustment: {
      invalidatesTags(result, error, { id }) {
        return [
          { type: 'InventoryAdjustment', id },
          'InventoryAdjustment',
          'Medicine',
          'MedicalDevice',
        ];
      },
    },
    getInventoryAdjustmentById: {
      providesTags: (result, error, id) => [{ type: 'InventoryAdjustment', id }],
    },
    createInventoryMaterialReceipt: {
      invalidatesTags(result, error, { id }) {
        return [{ type: 'InventoryMaterialReceipt', id }];
      },
    },
  },
});

export const {
  useGetInventoryAdjustmentsQuery,
  useCreateInventoryAdjustmentMutation,
  useUpdateInventoryAdjustmentMutation,
  useValidateInventoryAdjustmentMutation,
  useGetInventoryAdjustmentByIdQuery,
  useGetInventoryMaterialReceiptsQuery,
  useCreateInventoryMaterialReceiptMutation,
} = intentoryApi;
