import { IPagination, IReqGlobalRsql } from 'src/@types/base';
import { IProviderProcedure, IReqProviderProcedure } from 'src/@types/provider-procedure';
import { getProviderId } from 'src/utils/local-storage';
import { normalizeParams } from 'src/utils/convertFilterString';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/provider-procedures';

const providerProcedureApi = baseKemedisApi.enhanceEndpoints({
  addTagTypes: [`${prefix}`, 'ProviderProcedure'],
});

const _apiWithTaggedEndpoint = providerProcedureApi.injectEndpoints({
  endpoints: (build) => ({
    getProviderProcedures: build.query<IPagination<IProviderProcedure>, Partial<IReqGlobalRsql>>({
      query: (reqRsql) => ({
        url: `${prefix}/providers/${getProviderId()}`,
        method: 'GET',
        params: normalizeParams(reqRsql),
      }),
      providesTags: ['/services/arjuna/api/kemedis/provider-procedures'],
    }),
    getProviderProcedureById: build.query<IProviderProcedure, string>({
      query: (id) => ({
        url: `${prefix}/${id}`,
        method: 'GET',
      }),
    }),
    createProviderProcedure: build.mutation<IProviderProcedure, IReqProviderProcedure>({
      query: (data) => ({
        url: `${prefix}`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['/services/arjuna/api/kemedis/provider-procedures', 'ProviderProcedure'],
    }),
    updateProviderProcedure: build.mutation<IProviderProcedure, IReqProviderProcedure>({
      query: (data) => ({
        url: `${prefix}/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['/services/arjuna/api/kemedis/provider-procedures', 'ProviderProcedure'],
    }),
  }),
});

export const {
  useGetProviderProceduresQuery,
  useGetProviderProcedureByIdQuery,
  useCreateProviderProcedureMutation,
  useUpdateProviderProcedureMutation,
} = _apiWithTaggedEndpoint;
