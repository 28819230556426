import { getProviderId } from 'src/utils/local-storage';
import { IPagination, IReqGlobalRsql } from 'src/@types/base';
import {
  IReqEncounter,
  IResEncounter,
  IReqProceedEncounter,
  IResEnconterPrepare,
  IReqUpdateEncounter,
  IReqVisitPlanBPJS,
  IReqVisitPlan,
} from 'src/@types/encounter';
import { normalizeParams } from 'src/utils/convertFilterString';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/encounters';

const encounterApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    // post /api/kemedis/encounters
    createEncounter: build.mutation<IResEncounter, IReqEncounter>({
      query: (data) => ({
        url: `${prefix}`,
        method: 'POST',
        data,
      }),
    }),

    // /api/kemedis/encounters/{id}
    getEncounterById: build.query<IResEncounter, string>({
      query: (id) => ({
        url: `${prefix}/${id}`,
        method: 'GET',
      }),
    }),

    // put /api/kemedis/encounters/{id}
    updateEncounter: build.mutation<IResEncounter, IReqUpdateEncounter>({
      query: (data) => ({
        url: `${prefix}/${data.id}`,
        method: 'PUT',
        data,
      }),
    }),

    // get /api/kemedis/encounters/prepare/edit/{providerId}
    getEncounterPrepareEdit: build.query<IResEnconterPrepare, void>({
      query: () => ({
        url: `${prefix}/prepare/edit/${getProviderId()}`,
        method: 'GET',
      }),
    }),

    // post /api/kemedis/encounters/proceed/{encounterId}
    proceedEncounter: build.mutation<IResEncounter, IReqProceedEncounter>({
      query: (data) => ({
        url: `${prefix}/proceed/${data.encounterId}`,
        method: 'POST',
        data,
      }),
    }),

    // get /api/kemedis/encounters/provider/{providerId}
    getEncountersByProviderId: build.query<IPagination<IResEncounter>, Partial<IReqGlobalRsql>>({
      query: (reqRsql) => ({
        url: `${prefix}/provider/${getProviderId()}`,
        method: 'GET',
        params: normalizeParams(reqRsql),
      }),
    }),

    cancelEncounterById: build.mutation<IResEncounter, string>({
      query: (id) => ({
        url: `${prefix}/cancel/${id}`,
        method: 'POST',
      }),
    }),

    createVisitPlanBPJS: build.mutation<IResEncounter, IReqVisitPlanBPJS>({
      query: (data) => ({
        url: `${prefix}/visit-plan/bpjs`,
        method: 'POST',
        data,
      }),
    }),

    createVisitPlanNonBPJS: build.mutation<IResEncounter, IReqVisitPlan>({
      query: (data) => ({
        url: `${prefix}/visit-plan`,
        method: 'POST',
        data,
      }),
    }),
  }),
});

encounterApi.enhanceEndpoints({
  addTagTypes: ['Encounter'],
  endpoints: {
    getEncountersByProviderId: {
      providesTags: ['Encounter'],
    },
    getEncounterById: {
      providesTags: ['Encounter'],
    },
    createEncounter: {
      invalidatesTags: ['Encounter'],
    },
    updateEncounter: {
      invalidatesTags: ['Encounter'],
    },
    cancelEncounterById: {
      invalidatesTags: ['Encounter'],
    },
    proceedEncounter: {
      invalidatesTags: ['Encounter'],
    },
    createVisitPlanBPJS: {
      invalidatesTags: ['Encounter'],
    },
    createVisitPlanNonBPJS: {
      invalidatesTags: ['Encounter'],
    },
  },
});

export const {
  useCreateEncounterMutation,
  useGetEncounterByIdQuery,
  useUpdateEncounterMutation,
  useGetEncounterPrepareEditQuery,
  useProceedEncounterMutation,
  useGetEncountersByProviderIdQuery,
  useCancelEncounterByIdMutation,
  useCreateVisitPlanBPJSMutation,
  useCreateVisitPlanNonBPJSMutation,
} = encounterApi;
