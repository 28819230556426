// @mui
import { Stack, Button, Typography, Box } from '@mui/material';
import { KemedisLogoMini } from 'src/assets/illustrations';
import { useNavigate } from 'react-router';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// locales
import { useLocales } from '../../../locales';
// routes
import { PATH_DASHBOARD, PATH_DOCS } from '../../../routes/paths';

// ----------------------------------------------------------------------

export default function NavDocs() {
  const { user } = useAuthContext();

  const navigate = useNavigate();

  const { translate } = useLocales();

  return (
    <Stack
      spacing={3}
      sx={{
        px: 5,
        pb: 5,
        mt: 5,
        width: 1,
        display: 'block',
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          width: 40,
          height: 40,
          display: 'inline-flex',
        }}
        component="img"
        src={KemedisLogoMini}
      />

      <div>
        <Typography gutterBottom variant="subtitle1">
          {/* {`${translate('docs.hi')}, ${user?.displayName}`} */}
          {user?.displayName}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>
          {/* {`${translate('docs.description')}`} */}
          {user?.role}
        </Typography>
      </div>

      <Button
        onClick={() => navigate(PATH_DASHBOARD.features)}
        // href={PATH_DASHBOARD.features}
        // target="_blank"
        // rel="noopener"
        variant="contained"
      >
        Upgrade to Pro
      </Button>
    </Stack>
  );
}
