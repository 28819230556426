// TYPE
import { IPagination, IReqGlobalRsql } from 'src/@types/base';
import { IEmployee, IReqEmployeeUpdateStatus } from 'src/@types/employee';
import { IAuthorityRole } from 'src/@types/authority-role';
import { IUserManagement, IReqUserManagementUpdate } from 'src/@types/user-management';
// UTILS
import { getProviderId } from 'src/utils/local-storage';
// BASE API
import { normalizeParams } from 'src/utils/convertFilterString';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/api/management';

type IEmployeeOvr = IEmployee & { action: string };
const managementApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    // EMPLOYEES
    getEmployees: build.query<IPagination<IEmployeeOvr>, Partial<IReqGlobalRsql>>({
      query: (reqRsql) => ({
        url: `${prefix}/employees/get-employees/${getProviderId()}`,
        method: 'GET',
        params: normalizeParams(reqRsql),
      }),
    }),
    createEmployee: build.mutation<IEmployee, IEmployee>({
      query: (data) => ({
        url: `${prefix}/employees`,
        method: 'POST',
        data,
      }),
    }),
    updateStatusEmployee: build.mutation<IEmployee, IReqEmployeeUpdateStatus>({
      query: (data) => ({
        url: `${prefix}/update-status-employee/id/${data.id}`,
        method: 'PUT',
        data,
      }),
    }),
    getEmployeeById: build.query<IEmployee, string>({
      query: (id) => ({
        url: `${prefix}/employee/id/${id}`,
        method: 'GET',
      }),
    }),
    updateEmployeeById: build.mutation<IEmployee, IEmployee>({
      query: (data) => ({
        url: `${prefix}/update-employee/id/${data.id}`,
        method: 'PUT',
        data,
      }),
    }),
    // END EMPLOYEES

    // AUTHORITY ROLES
    getAuthorities: build.query<IPagination<IAuthorityRole>, Partial<IReqGlobalRsql>>({
      query: (reqRsql) => ({
        url: `${prefix}/authorities`,
        method: 'GET',
        params: normalizeParams(reqRsql),
      }),
    }),
    // END AUTHORITY ROLES

    // USER MANAGEMENT
    getUsers: build.query<IPagination<IUserManagement>, Partial<IReqGlobalRsql>>({
      query: (reqRsql) => ({
        url: `${prefix}/user/get-users/${getProviderId()}`,
        method: 'GET',
        params: normalizeParams(reqRsql),
      }),
    }),
    getUserByEmail: build.query<IUserManagement, string>({
      query: (email) => ({
        url: `${prefix}/users/${email}`,
        method: 'GET',
      }),
    }),
    updateUserRole: build.mutation<IUserManagement, IReqUserManagementUpdate>({
      query: (data) => ({
        url: `${prefix}/update-user-role`,
        method: 'PUT',
        data: {
          ...data,
          providerId: getProviderId(),
        },
      }),
    }),
    createUserRole: build.mutation<IUserManagement, IReqUserManagementUpdate>({
      query: (data) => ({
        url: `${prefix}/user/create-user-employee-provider`,
        method: 'POST',
        data: {
          ...data,
          providerId: getProviderId(),
        },
      }),
    }),
    // END USER MANAGEMENT
  }),
});

export const {
  // EMPLOYEES
  useGetEmployeesQuery,
  useGetEmployeeByIdQuery,
  useCreateEmployeeMutation,
  useUpdateStatusEmployeeMutation,
  useUpdateEmployeeByIdMutation,
  // END EMPLOYEES

  // AUTHORITY ROLES
  useGetAuthoritiesQuery,
  // END AUTHORITY ROLES

  // USER MANAGEMENT
  useGetUsersQuery,
  useGetUserByEmailQuery,
  useUpdateUserRoleMutation,
  useCreateUserRoleMutation,
  // END USER MANAGEMENT
} = managementApi;

managementApi.enhanceEndpoints({
  addTagTypes: [`${prefix}/employees`, `${prefix}/user`],
  endpoints: {
    getEmployees: {
      providesTags: [`/api/management/employees`],
    },
    createEmployee: {
      invalidatesTags: [`/api/management/employees`],
    },
    updateStatusEmployee: {
      invalidatesTags: [`/api/management/employees`],
    },
    updateEmployeeById: {
      invalidatesTags: [`/api/management/employees`],
    },

    // permissions
    getUsers: {
      providesTags: [`/api/management/user`],
    },
    updateUserRole: {
      invalidatesTags: [`/api/management/user`],
    },
    createUserRole: {
      invalidatesTags: [`/api/management/user`],
    },
  },
});
