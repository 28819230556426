import { getProviderId } from 'src/utils/local-storage';
import { IPagination, IReqGlobalRsql } from 'src/@types/base';
import { IPayment, IReqPayment } from 'src/@types/payment';
import { normalizeParams } from 'src/utils/convertFilterString';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/payments';

const paymentApi = baseKemedisApi.enhanceEndpoints({
  addTagTypes: [
    `${prefix}`,
    '/services/arjuna/api/kemedis/pharmacy-recipe',
    '/services/arjuna/api/kemedis/pharmacy/sales',
    '/services/kresna/api/kemedis/dashboard/main',
    'Bills',
    'PharmacyRecipe',
    'MainDashboard',
  ],
});

const _apiWithTaggedEndpoint = paymentApi.injectEndpoints({
  endpoints: (build) => ({
    getPayments: build.query<IPagination<IPayment>, Partial<IReqGlobalRsql>>({
      query: (reqRsql) => ({
        url: `${prefix}/providers/${getProviderId()}`,
        method: 'GET',
        params: normalizeParams(reqRsql),
      }),
      providesTags: ['/services/arjuna/api/kemedis/payments'],
    }),
    getPaymentById: build.query<IPayment, string>({
      query: (id) => ({
        url: `${prefix}/${id}`,
        method: 'GET',
      }),
    }),
    getPaymentByBillId: build.query<IPayment, string>({
      query: (id) => ({
        url: `${prefix}/bills/${id}`,
        method: 'GET',
      }),
    }),
    createPayment: build.mutation<IPayment, IReqPayment>({
      query: (data) => ({
        url: `${prefix}`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        '/services/arjuna/api/kemedis/payments',
        '/services/arjuna/api/kemedis/pharmacy-recipe',
        '/services/kresna/api/kemedis/dashboard/main',
        'Bills',
        'PharmacyRecipe',
        'MainDashboard',
      ],
    }),
    cancelPayment: build.mutation<IPayment, string>({
      query: (id) => ({
        url: `${prefix}/bills/cancel/${id}`,
        method: 'POST',
      }),
      invalidatesTags: [
        '/services/arjuna/api/kemedis/payments',
        '/services/arjuna/api/kemedis/pharmacy-recipe',
        '/services/kresna/api/kemedis/dashboard/main',
        'Bills',
        'PharmacyRecipe',
        'MainDashboard',
      ],
    }),
  }),
});

export const {
  useGetPaymentsQuery,
  useGetPaymentByIdQuery,
  useGetPaymentByBillIdQuery,
  useCreatePaymentMutation,
  useCancelPaymentMutation,
} = _apiWithTaggedEndpoint;
