export const getErrMessage = (err: any, defaultMessage: string) => {
  try {
    let errMessage;

    const { status, data } = err;

    if (data) {
      errMessage = data.detail || data.title || data.message || defaultMessage;
    } else {
      errMessage = err.detail || err.title || err.message || defaultMessage;
    }

    return `${status} ${errMessage}`;
  } catch {
    return defaultMessage;
  }
};
