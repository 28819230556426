import { cloneDeep } from 'lodash';
import { IReqSoap, ISoap } from 'src/@types/soap';

export const soaFormDefaultValue = (
  soap?: ISoap,
  soapHist?: ISoap[],
  soapById?: ISoap
): IReqSoap => {
  const reqSoapAssessments = soap?.soapAssessments?.map((item) => ({
    caseTypeCode: item?.caseTypeCode || '',
    diagnosisTypeCode: item?.diagnosisTypeCode || '',
    icd10Id: item?.icd10Id || '',
    note: item?.note || '',
  }));

  const cloneSoapVitalSigns = cloneDeep(soap?.soapVitalSigns);

  const orderSoapVitalSigns = cloneSoapVitalSigns?.sort((a, b) => a.order - b.order);

  const result: IReqSoap = {
    allergyIntolerance: soap?.allergyIntolerance || '',
    cashierNote: soap?.cashierNote || '',
    chiefComplaint: soap?.chiefComplaint || '',
    clinicalDiagnosis: soap?.clinicalDiagnosis || '',
    currentDiseaseRecord: soap?.currentDiseaseRecord || '',
    diseaseRecordHis: soap?.diseaseRecordHis || '',
    encounterId: soap?.encounterId || '',
    familyMemberHistory: soap?.familyMemberHistory || '',
    immunization: soap?.immunization || '',
    medicationStatement: soap?.medicationStatement || '',
    nurseClinicalDiagnosis: soap?.nurseClinicalDiagnosis || '',
    operationHistory: soap?.operationHistory || '',
    pharmacyNote: soap?.pharmacyNote || '',
    physicalExamination: soap?.physicalExamination || '',
    practitionerId: soap?.practitionerId || '',
    soapAssessments: reqSoapAssessments || [
      {
        caseTypeCode: 'CASE_TYPE_01',
        diagnosisTypeCode: 'DIAGNOSIS_TYPE_01',
        icd10Id: '',
        note: '',
      },
    ],
    soapVitalSigns: orderSoapVitalSigns?.map((item) => ({
      denominatorValue: item?.denominatorValue,
      numeratorValue:
        item?.denominatorValue !== 0
          ? `${item?.numeratorValue}/${item?.denominatorValue}`
          : item?.numeratorValue,
      vitalSignId: item?.vitalSignId,
    })) || [
      {
        denominatorValue: '',
        numeratorValue: '',
        vitalSignId: '',
      },
    ],
    socialHistory: soap?.socialHistory || '',
    soapAllergyIntolerances: [],
    soapChiefComplaints: [],
    soapMedicationProcedures: [],
    soapMedicationRequests: [],
    odontogram: {
      note: soapById?.soapOdontogram?.note || soap?.soapOdontogram?.note || '',
      soapOdontogramConditions:
        (soapById?.soapOdontogram?.soapOdontogramConditions &&
          soapById?.soapOdontogram?.soapOdontogramConditions.length > 0 &&
          soapById?.soapOdontogram?.soapOdontogramConditions) ||
        soapHist?.[0]?.soapOdontogram?.soapOdontogramConditions ||
        [],
      soapOdontogramProtesas:
        (soapById?.soapOdontogram?.soapOdontogramProtesas &&
          soapById?.soapOdontogram?.soapOdontogramProtesas.length > 0 &&
          soapById?.soapOdontogram?.soapOdontogramProtesas) ||
        soapHist?.[0]?.soapOdontogram?.soapOdontogramProtesas ||
        [],
      soapOdontogramRestorations:
        (soapById?.soapOdontogram?.soapOdontogramRestorations &&
          soapById?.soapOdontogram?.soapOdontogramRestorations.length > 0 &&
          soapById?.soapOdontogram?.soapOdontogramRestorations) ||
        soapHist?.[0]?.soapOdontogram?.soapOdontogramRestorations ||
        [],
    },
  };

  return result;
};
