import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Stack, Typography, Link } from '@mui/material';
// auth
import { Login_3 } from 'src/assets/images';
// layouts
import { PATH_AUTH } from 'src/routes/paths';
import LoginLayout from '../../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <LoginLayout title="Selamat datang di Kemedis!" illustration={Login_3}>
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Typography variant="h4">Masuk di Kemedis</Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">Belum punya akun Kemedis?&nbsp;</Typography>

          <Link component={RouterLink} to={PATH_AUTH.onboarding('facility')} variant="subtitle2">
            Daftar
          </Link>
        </Stack>
      </Stack>

      <AuthLoginForm />
    </LoginLayout>
  );
}
