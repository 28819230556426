import { IBpjsVclaim, IReqBpjsVclaim } from 'src/@types/bpjs-vclaim';
import { getProviderId } from 'src/utils/local-storage';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/bpjs-vclaim';

export const bpjsVclaimApi = baseKemedisApi.enhanceEndpoints({
  addTagTypes: [`${prefix}`, 'BpjsVclaim'],
});

const _apiWithTaggedEndpoint = bpjsVclaimApi.injectEndpoints({
  endpoints: (build) => ({
    getBpjsVclaim: build.query<IBpjsVclaim, unknown>({
      query: () => ({
        url: `${prefix}/${getProviderId()}`,
        method: 'GET',
      }),
      providesTags: [`${prefix}`, 'BpjsVclaim'],
    }),
    createBpjsVclaim: build.mutation<IBpjsVclaim, IReqBpjsVclaim>({
      query: (data) => ({
        url: `${prefix}`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [`${prefix}`, 'BpjsVclaim'],
    }),
    updateBpjsVclaim: build.mutation<IBpjsVclaim, IReqBpjsVclaim>({
      query: (data) => ({
        url: `${prefix}/${getProviderId()}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: [`${prefix}`, 'BpjsVclaim'],
    }),
  }),
});

export const { useGetBpjsVclaimQuery, useCreateBpjsVclaimMutation, useUpdateBpjsVclaimMutation } =
  _apiWithTaggedEndpoint;
