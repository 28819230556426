import { IPagination, IReqGlobalRsql } from 'src/@types/base';
import {
  ILocation,
  ILocationLabelValueList,
  IPostLocation,
  IPrepareLocation,
} from 'src/@types/location';
import { getProviderId } from 'src/utils/local-storage';
import { normalizeParams } from 'src/utils/convertFilterString';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/locations';

const locationApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    getLocations: build.query<IPagination<ILocation>, Partial<IReqGlobalRsql>>({
      query: (reqRsql) => ({
        url: `${prefix}/${getProviderId()}`,
        method: 'GET',
        params: normalizeParams(reqRsql),
      }),
    }),
    getLocationById: build.query<ILocation, string>({
      query: (id) => ({
        url: `${prefix}/id/${id}`,
        method: 'GET',
      }),
    }),
    getLocationPrepare: build.query<IPrepareLocation, void>({
      query: () => ({
        url: `${prefix}/prepare/${getProviderId()}`,
        method: 'GET',
      }),
    }),
    getLocationByOrgId: build.query<ILocationLabelValueList, string>({
      query: (orgId) => ({
        url: `${prefix}/labelvalues/organization/${orgId}`,
        method: 'GET',
      }),
    }),
    createLocation: build.mutation<IPostLocation, ILocation>({
      query: (data) => ({
        url: `${prefix}`,
        method: 'POST',
        data,
      }),
    }),
    updateLocation: build.mutation<IPostLocation, ILocation>({
      query: (data) => ({
        url: `${prefix}/${data.id}`,
        method: 'PUT',
        data,
      }),
    }),
  }),
});

locationApi.enhanceEndpoints({
  addTagTypes: ['Location'],
  endpoints: {
    getLocations: {
      providesTags: (result) =>
        result?.content
          ? [
              ...result.content.map(({ locationId }) => ({
                type: 'Location' as const,
                id: locationId,
              })),
              'Location',
            ]
          : ['Location'],
    },
    createLocation: {
      invalidatesTags: ['Location'],
    },
    updateLocation: {
      invalidatesTags: ['Location'],
    },
  },
});

export const {
  useGetLocationsQuery,
  useGetLocationByIdQuery,
  useGetLocationPrepareQuery,
  useGetLocationByOrgIdQuery,
  useCreateLocationMutation,
  useUpdateLocationMutation,
} = locationApi;
