import { IPagination, IReqGlobalRsql } from 'src/@types/base';
import { IPharmacySale, IPharmacySalePrepare, IReqPharmacySale } from 'src/@types/pharmacy-sale';
import { getProviderId } from 'src/utils/local-storage';
import { normalizeParams } from 'src/utils/convertFilterString';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/pharmacy/sales';

const pharmacySaleApi = baseKemedisApi.enhanceEndpoints({
  addTagTypes: [
    `${prefix}`,
    'Bills',
    'MainDashboard',
    '/services/kresna/api/kemedis/dashboard/main',
  ],
});

const _apiWithTaggedEndpoint = pharmacySaleApi.injectEndpoints({
  endpoints: (build) => ({
    getPharmacySales: build.query<IPagination<IPharmacySale>, Partial<IReqGlobalRsql>>({
      query: (reqRsql) => ({
        url: `${prefix}/provider/${getProviderId()}`,
        method: 'GET',
        params: normalizeParams(reqRsql),
      }),
      providesTags: ['/services/arjuna/api/kemedis/pharmacy/sales'],
    }),
    getPharmacySaleById: build.query<IPharmacySale, string>({
      query: (id) => ({
        url: `${prefix}/${id}`,
        method: 'GET',
      }),
    }),
    getPharmacySalePrepare: build.query<IPharmacySalePrepare, unknown>({
      query: () => ({
        url: `${prefix}/prepare/${getProviderId()}`,
        method: 'GET',
      }),
    }),
    createPharmacySale: build.mutation<IPharmacySale, IReqPharmacySale>({
      query: (data) => ({
        url: `${prefix}`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        '/services/arjuna/api/kemedis/pharmacy/sales',
        '/services/kresna/api/kemedis/dashboard/main',
        'Bills',
        'MainDashboard',
      ],
    }),
    cancelPharmacySale: build.mutation<IPharmacySale, string>({
      query: (id) => ({
        url: `${prefix}/cancel/${id}`,
        method: 'POST',
      }),
      invalidatesTags: [
        '/services/arjuna/api/kemedis/pharmacy/sales',
        '/services/kresna/api/kemedis/dashboard/main',
        'Bills',
        'MainDashboard',
      ],
    }),
  }),
});

export const {
  useGetPharmacySalesQuery,
  useGetPharmacySaleByIdQuery,
  useGetPharmacySalePrepareQuery,
  useCreatePharmacySaleMutation,
  useCancelPharmacySaleMutation,
} = _apiWithTaggedEndpoint;
