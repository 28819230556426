import {
  IParamsVCaimMonitoring,
  IParamsVClaimMonitoringKlaim,
  IParamsVClaimMonitoringKunjungan,
  IResMonitoringKunjungan,
  IResVClaimMonitoringKlaim,
  IVClaimMonitoring,
} from 'src/@types/bpjs-vclaim';
import { getProviderId } from 'src/utils/local-storage';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/bpjs/api/vclaim';

const vClaimMonitoringApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    getVClaimMonitoring: build.query<IVClaimMonitoring, IParamsVCaimMonitoring>({
      query: (params) => ({
        url: `${prefix}/monitoring/histori-pelayanan/peserta/${getProviderId()}/${params.noKartu}/${
          params.tglMulai
        }/${params.tglAkhir}`,
        method: 'GET',
      }),
    }),
    getVClaimMonitoringKunjungan: build.query<
      IResMonitoringKunjungan,
      IParamsVClaimMonitoringKunjungan
    >({
      query: (params) => ({
        url: `${prefix}/monitoring/kunjungan/${getProviderId()}/${params.tanggal}/${
          params.jnsPelayanan
        }`,
        method: 'GET',
      }),
    }),
    getVClaimMonitoringKlaim: build.query<IResVClaimMonitoringKlaim, IParamsVClaimMonitoringKlaim>({
      query: (params) => ({
        url: `${prefix}/monitoring/klaim/${getProviderId()}/${params.tanggal}/${
          params.jnsPelayanan
        }/${params.status}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetVClaimMonitoringQuery,
  useLazyGetVClaimMonitoringQuery,
  useGetVClaimMonitoringKunjunganQuery,
  useLazyGetVClaimMonitoringKunjunganQuery,
  useGetVClaimMonitoringKlaimQuery,
  useLazyGetVClaimMonitoringKlaimQuery,
} = vClaimMonitoringApi;
