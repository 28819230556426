import { IReqEncounterSatuSehat, IResEncounterSatuSehat } from 'src/@types/encounter-satu-sehat';
import { getProviderId } from 'src/utils/local-storage';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/ihs-api/encounter';

const encounterSatuSehatApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    getEncounterSatuSehat: build.query<IResEncounterSatuSehat, string>({
      query: (ihsEncounterId) => ({
        url: `${prefix}/${getProviderId()}/${ihsEncounterId}`,
        method: 'GET',
      }),
    }),
    createEncounterSatuSehatNew: build.mutation<IResEncounterSatuSehat, IReqEncounterSatuSehat>({
      query: (data) => ({
        url: `${prefix}/${getProviderId()}`,
        method: 'POST',
        data,
      }),
    }),
  }),
});

export const { useGetEncounterSatuSehatQuery, useCreateEncounterSatuSehatNewMutation } =
  encounterSatuSehatApi;
