import { IHealthCareOnboardingPrepare, IReqHealthCareOnboarding } from 'src/@types/health-care';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/api/kemedis/healthcare-provider';
// https://develop.kemedis.co/api/kemedis/healthcare-provider/onboarding/prepare
const healthCareProviderApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    getOnboardingPrepare: build.query<IHealthCareOnboardingPrepare, unknown>({
      query: () => ({
        url: `${prefix}/onboarding/prepare`,
        method: 'GET',
      }),
    }),

    createOnboarding: build.mutation<IHealthCareOnboardingPrepare, IReqHealthCareOnboarding>({
      query: (data) => ({
        url: `${prefix}/onboarding/`,
        method: 'POST',
        data,
      }),
    }),
  }),
});

export const { useGetOnboardingPrepareQuery, useCreateOnboardingMutation } = healthCareProviderApi;
