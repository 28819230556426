import { getProviderId } from 'src/utils/local-storage';
import {
  IDiagnosaList,
  IFaskesList,
  IKelasRawatList,
  IPoliList,
  IProvinceList,
} from 'src/@types/vclaim-ref';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/bpjs/api/vclaim/ref';

const vClaimRefApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    getKlsRawatRef: build.query<IKelasRawatList, void>({
      query: () => ({
        url: `${prefix}/kelasrawat/${getProviderId()}`,
        method: 'GET',
      }),
    }),
    getPoliRef: build.query<IPoliList, string>({
      query: (param) => ({
        url: `${prefix}/poli/${getProviderId()}/${param}`,
        method: 'GET',
      }),
    }),
    getProvinceRef: build.query<IProvinceList, void>({
      query: () => ({
        url: `${prefix}/propinsi/${getProviderId()}`,
        method: 'GET',
      }),
    }),
    getDistrictRef: build.query<IProvinceList, string>({
      query: (param) => ({
        url: `${prefix}/kabupaten/${getProviderId()}/${param}`,
        method: 'GET',
      }),
    }),
    getSubDistrictRef: build.query<IProvinceList, string>({
      query: (param) => ({
        url: `${prefix}/kecamatan/${getProviderId()}/${param}`,
        method: 'GET',
      }),
    }),
    getDiagnosaRef: build.query<IDiagnosaList, string>({
      query: (param) => ({
        url: `${prefix}/diagnosa/${getProviderId()}/${param}`,
        method: 'GET',
      }),
    }),
    getFaskesRef: build.query<IFaskesList, { param: string; faskesAsal: string }>({
      query: (param) => ({
        url: `${prefix}/faskes/${getProviderId()}/${param.param}/${param.faskesAsal}`,
        method: 'GET',
      }),
    }),
  }),
});

vClaimRefApi.enhanceEndpoints({
  addTagTypes: ['VClaimRef'],
  endpoints: {
    getKlsRawatRef: {
      providesTags: ['VClaimRef'],
    },
    getPoliRef: {
      providesTags: ['VClaimRef'],
    },
    getProvinceRef: {
      providesTags: ['VClaimRef'],
    },
    getDistrictRef: {
      providesTags: ['VClaimRef'],
    },
    getSubDistrictRef: {
      providesTags: ['VClaimRef'],
    },
    getDiagnosaRef: {
      providesTags: ['VClaimRef'],
    },
    getFaskesRef: {
      providesTags: ['VClaimRef'],
    },
  },
});

export const {
  useGetKlsRawatRefQuery,
  useGetPoliRefQuery,
  useGetProvinceRefQuery,
  useGetDistrictRefQuery,
  useGetSubDistrictRefQuery,
  useGetDiagnosaRefQuery,
  useGetFaskesRefQuery,
} = vClaimRefApi;
