import { IPagination, IReqGlobalRsql } from 'src/@types/base';
import {
  IPharmacyRecipe,
  IPreparePharmacyRecipe,
  IReqPharmacyRecipeSubmission,
  IReqPharmacyRecipeVerify,
} from 'src/@types/pharmacy-recipe';
import { getProviderId } from 'src/utils/local-storage';
import { normalizeParams } from 'src/utils/convertFilterString';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/pharmacy-recipe';

const pharmacyRecipeApi = baseKemedisApi.enhanceEndpoints({
  addTagTypes: [`${prefix}`, 'PharmacyRecipe'],
});

const _apiWithTaggedEndpoint = pharmacyRecipeApi.injectEndpoints({
  endpoints: (build) => ({
    getPharmacyRecipes: build.query<IPagination<IPharmacyRecipe>, Partial<IReqGlobalRsql>>({
      query: (reqRsql) => ({
        url: `${prefix}/provider/${getProviderId()}`,
        method: 'GET',
        params: normalizeParams(reqRsql),
      }),
      providesTags: ['/services/arjuna/api/kemedis/pharmacy-recipe'],
    }),
    getPharmacyRecipeById: build.query<IPharmacyRecipe, string>({
      query: (id) => ({
        url: `${prefix}/${id}`,
        method: 'GET',
      }),
    }),
    createPharmacyRecipeVerify: build.mutation<IPharmacyRecipe, IReqPharmacyRecipeVerify>({
      query: (data) => ({
        url: `${prefix}/verify`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['/services/arjuna/api/kemedis/pharmacy-recipe', 'PharmacyRecipe'],
    }),
    createPharmacyRecipeSubmission: build.mutation<IPharmacyRecipe, IReqPharmacyRecipeSubmission>({
      query: (data) => ({
        url: `${prefix}/submission`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['/services/arjuna/api/kemedis/pharmacy-recipe', 'PharmacyRecipe'],
    }),
    getPreparePharmacyRecipe: build.query<IPreparePharmacyRecipe, unknown>({
      query: () => ({
        url: `${prefix}/prepare/search/${getProviderId()}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetPharmacyRecipesQuery,
  useGetPharmacyRecipeByIdQuery,
  useGetPreparePharmacyRecipeQuery,
  useCreatePharmacyRecipeVerifyMutation,
  useCreatePharmacyRecipeSubmissionMutation,
} = _apiWithTaggedEndpoint;
