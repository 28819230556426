import { IPagination, IReqGlobalRsql } from 'src/@types/base';
import { ISnomed } from 'src/@types/snomed';
import { normalizeParams } from 'src/utils/convertFilterString';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/snomeds';

const snomedApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    getSnomeds: build.query<IPagination<ISnomed>, Partial<IReqGlobalRsql>>({
      query: (reqRsql) => ({
        url: `${prefix}`,
        method: 'GET',
        params: normalizeParams(reqRsql),
      }),
    }),
  }),
});

export const { useGetSnomedsQuery } = snomedApi;
