import { IReqGlobalRsql } from 'src/@types/base';
import {
  IMaterialReport,
  IMaterialReportJson,
  IMaterialReportList,
  IPrepareMaterialReport,
} from 'src/@types/material-report';
import { getProviderId } from 'src/utils/local-storage';
import { normalizeParams } from 'src/utils/convertFilterString';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/report/material-reports';

const materialReportApi = baseKemedisApi.enhanceEndpoints({
  addTagTypes: [`${prefix}`],
});

const _apiWithTaggedEndpoint = materialReportApi.injectEndpoints({
  endpoints: (build) => ({
    getMaterialReports: build.query<IMaterialReportList, Partial<IReqGlobalRsql>>({
      query: (reqRsql) => ({
        url: `${prefix}/${getProviderId()}`,
        method: 'GET',
        params: normalizeParams(reqRsql),
      }),
      providesTags: ['/services/arjuna/api/kemedis/report/material-reports'],
    }),
    getMaterialReportJson: build.query<IMaterialReportJson, Partial<IReqGlobalRsql>>({
      query: (reqRsql) => ({
        url: `${prefix}/json/${getProviderId()}`,
        method: 'GET',
        params: normalizeParams(reqRsql),
      }),
    }),
    getMaterialReportPrepare: build.query<IPrepareMaterialReport, null>({
      query: () => ({
        url: `${prefix}/prepare/search/${getProviderId()}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetMaterialReportsQuery,
  useGetMaterialReportJsonQuery,
  useGetMaterialReportPrepareQuery,
} = _apiWithTaggedEndpoint;
