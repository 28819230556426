// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_ACCOUNT = '/acccount';
const ROOTS_ADMIN = '/account';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  onboarding: (step: 'facility' | 'company' | 'user' | 'success') =>
    path(ROOTS_AUTH, `/${step}/onboarding`),
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: (email: string) => path(ROOTS_AUTH, `/${email}/verify`),
  resetSuccess: path(ROOTS_AUTH, '/reset-success'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/reset/finish'),
  activate: path(ROOTS_AUTH, '/activate'),
};

export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  activate: path(ROOTS_ADMIN, '/activate'),
};

export const PATH_ACCOUNT = {
  root: ROOTS_ACCOUNT,
  activate: path(ROOTS_ACCOUNT, '/activate'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  features: path(ROOTS_DASHBOARD, '/features'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  fileManager: path(ROOTS_DASHBOARD, '/files-manager'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    file: path(ROOTS_DASHBOARD, '/file'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title: string) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },

  // ----------------------------------------------------------------------
  // KEMEDIS ROUTES

  // master
  master: {
    root: path(ROOTS_DASHBOARD, '/master'),
    // ihs
    ihs: {
      root: path(ROOTS_DASHBOARD, '/master/ihs'),
      // organization
      organization: {
        root: path(ROOTS_DASHBOARD, '/master/ihs/organization'),
        create: path(ROOTS_DASHBOARD, '/master/ihs/organization/create'),
        update: (id: string) => path(ROOTS_DASHBOARD, `/master/ihs/organization/${id}/update`),
      },
      // end organization

      // location
      location: {
        root: path(ROOTS_DASHBOARD, '/master/ihs/location'),
        create: path(ROOTS_DASHBOARD, '/master/ihs/location/create'),
        update: (id: string) => path(ROOTS_DASHBOARD, `/master/ihs/location/${id}/update`),
      },
      // end location

      // practitioner
      practitioner: {
        root: path(ROOTS_DASHBOARD, '/master/ihs/practitioner'),
        create: path(ROOTS_DASHBOARD, '/master/ihs/practitioner/create'),
        update: (id: string) => path(ROOTS_DASHBOARD, `/master/ihs/practitioner/${id}/update`),
        createSchedule: (id: string) =>
          path(ROOTS_DASHBOARD, `/master/ihs/practitioner/${id}/create-schedule`),
      },
      // end practitioner
    },

    // pharmacy
    pharmacy: {
      root: path(ROOTS_DASHBOARD, '/master/pharmacy'),

      // medicine input
      medicineInput: {
        root: path(ROOTS_DASHBOARD, '/master/pharmacy/medicine-input'),
        create: path(ROOTS_DASHBOARD, '/master/pharmacy/medicine-input/create'),
        update: (id: string) =>
          path(ROOTS_DASHBOARD, `/master/pharmacy/medicine-input/${id}/update`),
        createMedicine: path(ROOTS_DASHBOARD, '/master/pharmacy/medicine-input/create-medicine'),
        updateMedicine: (id: string) =>
          path(ROOTS_DASHBOARD, `/master/pharmacy/medicine-input/${id}/update-medicine`),
        createMedicalDevice: path(
          ROOTS_DASHBOARD,
          '/master/pharmacy/medicine-input/create-medical-device'
        ),
        updateMedicalDevice: (id: string) =>
          path(ROOTS_DASHBOARD, `/master/pharmacy/medicine-input/${id}/update-medical-device`),
      },
      // end medicine input
    },
    // end pharmacy

    // product and service
    productAndService: {
      root: path(ROOTS_DASHBOARD, '/master/product-and-service'),

      // product
      product: {
        root: path(ROOTS_DASHBOARD, '/master/product-and-service/product'),
        create: path(ROOTS_DASHBOARD, '/master/product-and-service/product/create'),
        update: (id: string) =>
          path(ROOTS_DASHBOARD, `/master/product-and-service/product/${id}/update`),
      },
      // end product
    },
    // end product and service

    // guarantor
    guarantor: {
      root: path(ROOTS_DASHBOARD, '/master/guarantor'),
      create: path(ROOTS_DASHBOARD, '/master/guarantor/create'),
      update: (id: string) => path(ROOTS_DASHBOARD, `/master/guarantor/${id}/update`),
    },
    // end guarantor
  },
  // end master

  // pharmacy service
  pharmacyService: {
    root: path(ROOTS_DASHBOARD, '/pharmacy-service'),
    ePrescribing: {
      root: path(ROOTS_DASHBOARD, '/pharmacy-service/e-prescribing'),
      verify: (id: string) => path(ROOTS_DASHBOARD, `/pharmacy-service/e-prescribing/${id}/verify`),
      update: (id: string) => path(ROOTS_DASHBOARD, `/pharmacy-service/e-prescribing/${id}/update`),
      submission: (id: string) =>
        path(ROOTS_DASHBOARD, `/pharmacy-service/e-prescribing/${id}/submission`),
    },
  },
  // end pharmacy service

  // cashier
  cashier: {
    root: path(ROOTS_DASHBOARD, '/cashier'),

    // general
    general: {
      root: path(ROOTS_DASHBOARD, '/cashier/general'),
      create: path(ROOTS_DASHBOARD, '/cashier/general/create'),
      repeatOrder: (id: string) => path(ROOTS_DASHBOARD, `/cashier/general/${id}/repeat-order`),
      view: (id: string) => path(ROOTS_DASHBOARD, `/cashier/general/${id}/view`),
      thermal: (id: string) => path(ROOTS_DASHBOARD, `/cashier/general/${id}/thermal`),
    },
    // end general

    // outpatient
    outpatient: {
      root: path(ROOTS_DASHBOARD, '/cashier/outpatient'),
      create: path(ROOTS_DASHBOARD, '/cashier/outpatient/create'),
      pay: (id: string) => path(ROOTS_DASHBOARD, `/cashier/outpatient/${id}/pay`),
      thermal: (id: string, type: string) =>
        path(ROOTS_DASHBOARD, `/cashier/outpatient/${id}/thermal/${type}`),
    },
    // end outpatient
  },
  // end cashier

  // management
  management: {
    root: path(ROOTS_DASHBOARD, '/management'),
    employees: {
      root: path(ROOTS_DASHBOARD, '/management/employees'),
      create: path(ROOTS_DASHBOARD, '/management/employees/create'),
      update: (id: string) => path(ROOTS_DASHBOARD, `/management/employees/${id}/update`),
    },
    roles: {
      root: path(ROOTS_DASHBOARD, '/management/roles'),
    },
    permissions: {
      root: path(ROOTS_DASHBOARD, '/management/permissions'),
      create: path(ROOTS_DASHBOARD, '/management/permissions/create'),
      update: (email: string) => path(ROOTS_DASHBOARD, `/management/permissions/${email}/update`),
    },
  },
  // end management

  // persediaan (inventory)
  inventory: {
    root: path(ROOTS_DASHBOARD, '/inventory'),
    stockMedicines: {
      root: path(ROOTS_DASHBOARD, '/inventory/stock-medicines'),
    },
    adjustStockMedicines: {
      root: path(ROOTS_DASHBOARD, '/inventory/adjust-stock-medicines'),
      create: path(ROOTS_DASHBOARD, '/inventory/adjust-stock-medicines/create'),
      update: (id: string) =>
        path(ROOTS_DASHBOARD, `/inventory/adjust-stock-medicines/${id}/update`),
    },
    materialReceipt: {
      root: path(ROOTS_DASHBOARD, '/inventory/material-receipt'),
      create: path(ROOTS_DASHBOARD, '/inventory/material-receipt/create'),
    },
  },
  // end persediaan (inventory)

  // admission
  bpjs: {
    root: path(ROOTS_DASHBOARD, '/bpjs'),
    vclaim: {
      root: path(ROOTS_DASHBOARD, '/bpjs/vclaim'),
      sep: {
        root: path(ROOTS_DASHBOARD, '/bpjs/vclaim/sep'),
        create: (id: string, tglSEP: string) =>
          path(ROOTS_DASHBOARD, `/bpjs/vclaim/sep/${id}/create/${tglSEP}`),
      },
      monitoring: {
        root: path(ROOTS_DASHBOARD, '/bpjs/vclaim/monitoring'),
      },
    },
  },
  admission: {
    root: path(ROOTS_DASHBOARD, '/admission'),
    patientRegistration: {
      root: path(ROOTS_DASHBOARD, '/admission/patient-registration'),
      create: path(ROOTS_DASHBOARD, '/admission/patient-registration/create'),
      update: (id: string) => path(ROOTS_DASHBOARD, `/admission/patient-registration/${id}/update`),
      searchExisting: path(ROOTS_DASHBOARD, '/admission/patient-registration/existing/search'),
      createExisting: (patientId: string) =>
        path(ROOTS_DASHBOARD, `/admission/patient-registration/existing/${patientId}/create`),
      createSep: (id: string, tglSEP: string) =>
        path(ROOTS_DASHBOARD, `/admission/patient-registration/${id}/create/${tglSEP}`),
      updateSep: (id: string, tglSEP: string) =>
        path(ROOTS_DASHBOARD, `/admission/patient-registration/${id}/update/${tglSEP}`),
      createVisitPlan: (noSEP: string, tglSEP: string) =>
        path(
          ROOTS_DASHBOARD,
          `/admission/patient-registration/${noSEP}/${tglSEP}/create-visit-plan`
        ),
      updateVisitPlan: (noSEP: string, tglSEP: string) =>
        path(
          ROOTS_DASHBOARD,
          `/admission/patient-registration/${noSEP}/${tglSEP}/update-visit-plan`
        ),
      createVisitPlanNonBpjs: (encounterId: string) =>
        path(
          ROOTS_DASHBOARD,
          `/admission/patient-registration/${encounterId}/create-visit-plan-non-bpjs`
        ),
      createRujukan: (encounterId: string) =>
        path(ROOTS_DASHBOARD, `/admission/patient-registration/${encounterId}/create-rujukan`),
      updateRujukan: (encounterId: string) =>
        path(ROOTS_DASHBOARD, `/admission/patient-registration/${encounterId}/update-rujukan`),
      createPenjaminan: (noSEP: string, tglSEP: string) =>
        path(
          ROOTS_DASHBOARD,
          `/admission/patient-registration/${noSEP}/${tglSEP}/create-penjaminan`
        ),

      createSepPcare: (id: string) =>
        path(ROOTS_DASHBOARD, `/admission/patient-registration/${id}/create-pcare`),
    },
    booking: {
      root: path(ROOTS_DASHBOARD, '/admission/booking'),
      create: path(ROOTS_DASHBOARD, '/admission/booking/create'),
      confirmation: (id: string) => path(ROOTS_DASHBOARD, `/admission/booking/${id}/confirmation`),
      afterConfirmation: (id: string, appointmentId: string) =>
        path(ROOTS_DASHBOARD, `/admission/booking/${id}/${appointmentId}/after-confirmation`),
      searchExisting: path(ROOTS_DASHBOARD, '/admission/booking/existing/search'),
      createExisting: (patientId: string) =>
        path(ROOTS_DASHBOARD, `/admission/booking/existing/${patientId}/create`),
    },
    // patient data : root, create, update
    patientData: {
      root: path(ROOTS_DASHBOARD, '/admission/patient-data'),
      create: path(ROOTS_DASHBOARD, '/admission/patient-data/create'),
      update: (id: string) => path(ROOTS_DASHBOARD, `/admission/patient-data/${id}/update`),
    },
  },
  // end admission

  // integrations
  integrations: {
    root: path(ROOTS_DASHBOARD, '/integrations'),
    bpjs: {
      root: path(ROOTS_DASHBOARD, '/integrations/bpjs'),
      queue: {
        root: path(ROOTS_DASHBOARD, '/integrations/bpjs/queue'),
        create: path(ROOTS_DASHBOARD, '/integrations/bpjs/queue/create'),
      },
      pcare: {
        root: path(ROOTS_DASHBOARD, '/integrations/bpjs/pcare'),
        create: path(ROOTS_DASHBOARD, '/integrations/bpjs/pcare/create'),
      },
      vclaim: {
        root: path(ROOTS_DASHBOARD, '/integrations/bpjs/vclaim'),
        create: path(ROOTS_DASHBOARD, '/integrations/bpjs/vclaim/create'),
      },
    },
    satuSehat: {
      root: path(ROOTS_DASHBOARD, '/integrations/satu-sehat'),
      ihs: {
        root: path(ROOTS_DASHBOARD, '/integrations/satu-sehat/ihs'),
        create: path(ROOTS_DASHBOARD, '/integrations/satu-sehat/ihs/create'),
        update: (id: string) => path(ROOTS_DASHBOARD, `/integrations/satu-sehat/ihs/${id}/update`),
      },
    },
  },
  // end integrations

  // reports
  reports: {
    root: path(ROOTS_DASHBOARD, '/reports'),
    // materialSale
    materialSale: {
      root: path(ROOTS_DASHBOARD, '/reports/material-sale'),
    },
    procedureService: {
      root: path(ROOTS_DASHBOARD, '/reports/procedure-service'),
    },
  },
  // end reports

  // services
  services: {
    root: path(ROOTS_DASHBOARD, '/services'),

    // outpatient
    outpatient: {
      root: path(ROOTS_DASHBOARD, '/services/outpatient'),
      create: path(ROOTS_DASHBOARD, '/services/outpatient/create'),
      initialAssessment: (practitionerId: string, encounterId: string) =>
        path(
          ROOTS_DASHBOARD,
          `/services/outpatient/${practitionerId}/${encounterId}/initial-assessment`
        ),
      icd10: (practitionerId: string, encounterId: string) =>
        path(ROOTS_DASHBOARD, `/services/outpatient/${practitionerId}/${encounterId}/icd10`),
      update: (practitionerId: string, encounterId: string) =>
        path(ROOTS_DASHBOARD, `/services/outpatient/${practitionerId}/${encounterId}/update`),
    },
    inpatient: {
      root: path(ROOTS_DASHBOARD, '/services/inpatient'),
    },
  },
  // end services

  // END KEMEDIS ROUTES
  // ----------------------------------------------------------------------
};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1';
