import { getProviderId } from 'src/utils/local-storage';
import { IPagination, IReqGlobalRsql } from 'src/@types/base';
import { IBill, IPrepareBill } from 'src/@types/bill';
import { normalizeParams } from 'src/utils/convertFilterString';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/bills';

const billApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    getBills: build.query<IPagination<IBill>, Partial<IReqGlobalRsql>>({
      query: (reqRsql) => ({
        url: `${prefix}/providers/${getProviderId()}`,
        method: 'GET',
        params: normalizeParams(reqRsql),
      }),
    }),
    getPrepareBill: build.query<IPrepareBill, unknown>({
      query: () => ({
        url: `${prefix}/prepare/edit/${getProviderId()}`,
        method: 'GET',
      }),
    }),
    getBillById: build.query<IBill, string>({
      query: (id) => ({
        url: `${prefix}/${id}`,
        method: 'GET',
      }),
    }),
  }),
});

billApi.enhanceEndpoints({
  addTagTypes: ['Bills'],
  endpoints: {
    getBills: {
      providesTags: ['Bills'],
    },
  },
});

export const { useGetBillsQuery, useGetBillByIdQuery, useGetPrepareBillQuery } = billApi;
