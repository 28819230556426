/* eslint-disable react/state-in-constructor */
/* eslint-disable react/destructuring-assignment */

import React, { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundaries extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state with the error and set hasError to true
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.hasError) {
      return (
        <div>
          <h1>Sorry.. there was an error</h1>
          {this.state.error && <p>Error message: {this.state.error.message}</p>}
          {this.state.error && <p>Error stack: {this.state.error.stack}</p>}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundaries;
