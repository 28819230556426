import { getProviderId } from 'src/utils/local-storage';
import { IReqIHSApiSatuSehat, IResIHSApiSatuSehat } from 'src/@types/satu-sehat';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/ihs-api';

const satuSehatApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    getIHSApi: build.query<IResIHSApiSatuSehat, void>({
      query: () => ({
        url: `${prefix}/${getProviderId()}`,
        method: 'GET',
      }),
    }),
    addIHSApi: build.mutation<IResIHSApiSatuSehat, IReqIHSApiSatuSehat>({
      query: (data) => ({
        url: `${prefix}`,
        method: 'POST',
        data,
      }),
    }),
    updateIHSApi: build.mutation<IResIHSApiSatuSehat, IReqIHSApiSatuSehat>({
      query: (data) => ({
        url: `${prefix}/${getProviderId()}`,
        method: 'PUT',
        data,
      }),
    }),
    deleteIHSApi: build.mutation<IResIHSApiSatuSehat, void>({
      query: () => ({
        url: `${prefix}/${getProviderId()}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetIHSApiQuery,
  useAddIHSApiMutation,
  useUpdateIHSApiMutation,
  useDeleteIHSApiMutation,
} = satuSehatApi;
