import {
  TParamVClaimRencanaKontrolJadwalDokter,
  TParamsRencanaKontrolList,
  TRencanaKontrolList,
  TReqDeleteVClaimRencanaKontrol,
  TReqVClaimRencanaKontrol,
  TResVClaimRencanaKontrol,
  TResVClaimRencanaKontrolJadwalDokter,
  TVClaimRencanaKontrolPrepare,
  TVClaimRencanaKontrolPrepareParams,
} from 'src/@types/vclaim-rencana-kontrol';
import { getProviderId } from 'src/utils/local-storage';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/bpjs/api/vclaim/rencana-kontrol';

const vClaimRencanaKontrolApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    getPrepareEditRencanaKontrol: build.query<
      TVClaimRencanaKontrolPrepare,
      TVClaimRencanaKontrolPrepareParams
    >({
      query: (req) => ({
        url: `${prefix}/prepare/edit/${req.noSEP}/${req.tglSEP}`,
        method: 'GET',
      }),
    }),
    getRencanaKontrol: build.query<TResVClaimRencanaKontrol, string>({
      query: (noSuratKontrol) => ({
        url: `${prefix}/${getProviderId()}/${noSuratKontrol}`,
        method: 'GET',
      }),
    }),
    getRencanaKontrolList: build.query<TRencanaKontrolList, TParamsRencanaKontrolList>({
      query: (param) => ({
        url: `${prefix}/list/${getProviderId()}/${param.tglAwal}/${param.tglAkhir}/${
          param.filterType
        }`,
        method: 'GET',
      }),
    }),
    getRencanaKontrolJadwalDokter: build.query<
      TResVClaimRencanaKontrolJadwalDokter,
      TParamVClaimRencanaKontrolJadwalDokter
    >({
      query: (param) => ({
        url: `${prefix}/jadwal-dokter/${getProviderId()}/${param.jenisKontrol}/${param.kodePoli}/${
          param.tanggalKontrol
        }`,
        method: 'GET',
      }),
    }),
    createVClaimRencanaKontrol: build.mutation<TReqVClaimRencanaKontrol, TReqVClaimRencanaKontrol>({
      query: (data) => ({
        url: `${prefix}`,
        method: 'POST',
        data,
      }),
    }),
    updateVClaimRencanaKontrol: build.mutation<TReqVClaimRencanaKontrol, TReqVClaimRencanaKontrol>({
      query: (data) => ({
        url: `${prefix}`,
        method: 'PUT',
        data,
      }),
    }),
    deleteRenanaKontrol: build.mutation<TResVClaimRencanaKontrol, TReqDeleteVClaimRencanaKontrol>({
      query: (data) => ({
        url: `${prefix}/delete`,
        method: 'DELETE',
        data,
      }),
    }),
  }),
});

vClaimRencanaKontrolApi.enhanceEndpoints({
  addTagTypes: ['VClaimRencanaKontrol'],
  endpoints: {
    getPrepareEditRencanaKontrol: {
      providesTags: ['VClaimRencanaKontrol'],
    },
    getRencanaKontrol: {
      providesTags: ['VClaimRencanaKontrol'],
    },
    getRencanaKontrolList: {
      providesTags: ['VClaimRencanaKontrol'],
    },
    getRencanaKontrolJadwalDokter: {
      providesTags: ['VClaimRencanaKontrol'],
    },
    createVClaimRencanaKontrol: {
      invalidatesTags: ['VClaimRencanaKontrol'],
    },
    updateVClaimRencanaKontrol: {
      invalidatesTags: ['VClaimRencanaKontrol'],
    },
    deleteRenanaKontrol: {
      invalidatesTags: ['VClaimRencanaKontrol'],
    },
  },
});

export const {
  useGetPrepareEditRencanaKontrolQuery,
  useCreateVClaimRencanaKontrolMutation,
  useGetRencanaKontrolQuery,
  useGetRencanaKontrolListQuery,
  useLazyGetRencanaKontrolListQuery,
  useUpdateVClaimRencanaKontrolMutation,
  useLazyGetRencanaKontrolQuery,
  useGetRencanaKontrolJadwalDokterQuery,
  useDeleteRenanaKontrolMutation,
} = vClaimRencanaKontrolApi;
