import { IBpjsPcare, IReqBpjsPcare } from 'src/@types/bpjs-pcare';
import { getProviderId } from 'src/utils/local-storage';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/bpjs-pcare';

export const bpjsPcareApi = baseKemedisApi.enhanceEndpoints({
  addTagTypes: [`${prefix}`, 'BpjsPcare'],
});

const _apiWithTaggedEndpoint = bpjsPcareApi.injectEndpoints({
  endpoints: (build) => ({
    getBpjsPcare: build.query<IBpjsPcare, unknown>({
      query: () => ({
        url: `${prefix}/${getProviderId()}`,
        method: 'GET',
      }),
      providesTags: [`${prefix}`, 'BpjsPcare'],
    }),
    createBpjsPcare: build.mutation<IBpjsPcare, IReqBpjsPcare>({
      query: (data) => ({
        url: `${prefix}`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [`${prefix}`, 'BpjsPcare'],
    }),
    updateBpjsPcare: build.mutation<IBpjsPcare, IReqBpjsPcare>({
      query: (data) => ({
        url: `${prefix}/${getProviderId()}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: [`${prefix}`, 'BpjsPcare'],
    }),
  }),
});

export const { useGetBpjsPcareQuery, useCreateBpjsPcareMutation, useUpdateBpjsPcareMutation } =
  _apiWithTaggedEndpoint;
