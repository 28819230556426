import { ILabelValue } from 'src/@types/base';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/api/kemedis/administrative';

const onboardingAdministrativeApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    getProvincesOnboarding: build.query<ILabelValue[], unknown>({
      query: () => ({
        url: `${prefix}/provinces/labelValues/`,
        method: 'GET',
      }),
    }),
    getCitiesOnboarding: build.query<ILabelValue[], string>({
      query: (provinceId) => ({
        url: `${prefix}/cities/labelValues/`,
        method: 'GET',
        params: { provinceId },
      }),
    }),
    getDistrictsOnboarding: build.query<ILabelValue[], string>({
      query: (cityId) => ({
        url: `${prefix}/district/labelValues/`,
        method: 'GET',
        params: { cityId },
      }),
    }),
    getSubDistrictsOnboarding: build.query<ILabelValue[], string>({
      query: (districtId) => ({
        url: `${prefix}/sub-district/labelValues/`,
        method: 'GET',
        params: { districtId },
      }),
    }),
  }),
});

export const {
  useGetCitiesOnboardingQuery,
  useGetDistrictsOnboardingQuery,
  useGetProvincesOnboardingQuery,
  useGetSubDistrictsOnboardingQuery,
} = onboardingAdministrativeApi;
