import { IResPractitionerByNik } from 'src/@types/practitioner-satu-sehat';
import { getProviderId } from 'src/utils/local-storage';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/ihs-api/practitioner';

const practitionerSatuSehatApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    getPractitionerIhsByNik: build.query<IResPractitionerByNik, string>({
      query: (nik) => ({
        url: `${prefix}/${getProviderId()}/nik/${nik}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetPractitionerIhsByNikQuery, useLazyGetPractitionerIhsByNikQuery } =
  practitionerSatuSehatApi;
