import { IBpjsQueue, IReqBpjsQueue } from 'src/@types/bpjs-queue';
import { getProviderId } from 'src/utils/local-storage';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/bpjs-queue';

export const bpjsQueueApi = baseKemedisApi.enhanceEndpoints({
  addTagTypes: [`${prefix}`, 'BpjsQueue'],
});

const _apiWithTaggedEndpoint = bpjsQueueApi.injectEndpoints({
  endpoints: (build) => ({
    getBpjsQueue: build.query<IBpjsQueue, unknown>({
      query: () => ({
        url: `${prefix}/${getProviderId()}`,
        method: 'GET',
      }),
      providesTags: [`${prefix}`, 'BpjsQueue'],
    }),
    createBpjsQueue: build.mutation<IBpjsQueue, IReqBpjsQueue>({
      query: (data) => ({
        url: `${prefix}`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [`${prefix}`, 'BpjsQueue'],
    }),
    updateBpjsQueue: build.mutation<IBpjsQueue, IReqBpjsQueue>({
      query: (data) => ({
        url: `${prefix}/${getProviderId()}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: [`${prefix}`, 'BpjsQueue'],
    }),
  }),
});

export const { useGetBpjsQueueQuery, useCreateBpjsQueueMutation, useUpdateBpjsQueueMutation } =
  _apiWithTaggedEndpoint;
