/* eslint-disable no-restricted-syntax */
import { cloneDeep, isEmpty } from 'lodash';
import { IFilterWithOperator, IReqGlobalRsql } from 'src/@types/base';

export const convertFilterStringWithOperator = (filter?: IFilterWithOperator): string => {
  if (!filter) {
    return '';
  }

  const queryParams: string[] = [];

  for (const [key, value] of Object.entries(filter)) {
    if (Array.isArray(value)) {
      if (value?.[0]?.type === 'or') {
        const queryParam = value
          .filter((item) => !isEmpty(item) && item.value !== '')
          .map((item) => `${key}${item.operator}${item.value}`)
          .join(' or ');
        if (queryParam !== '') {
          queryParams.push(`(${queryParam})`);
        }
      } else {
        const queryParam = value
          .filter((item) => !isEmpty(item) && item.value !== '')
          .map((item) => `${key}${item.operator}${item.value}`)
          .join(';');
        if (queryParam !== '') {
          queryParams.push(`(${queryParam})`);
        }
      }
    } else if (!isEmpty(value) && value.value !== '') {
      const queryParam = key.includes(',')
        ? key
            .split(',')
            .map((item) => `${item}${value.operator}'${value.value}'`)
            .join(' or ')
        : `${key}${value.operator}${value.value}`;
      queryParams.push(`(${queryParam})`);
    }
  }

  return queryParams.join(';');
};

export const normalizeParams = (params: IReqGlobalRsql) => {
  const cloneParams = cloneDeep(params);

  if (typeof cloneParams.filter === 'string' && cloneParams.filter === '') {
    delete cloneParams.filter;
  }

  return cloneParams;
};
