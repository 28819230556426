import { isEmpty } from 'lodash';
import { IResEncounter } from 'src/@types/encounter';
import { IReqSoapPlan, ISoap } from 'src/@types/soap';

export const soapPlanDefaulValue = (soap?: ISoap, encounter?: IResEncounter): IReqSoapPlan => {
  const result: IReqSoapPlan = {
    cashierNote: soap?.cashierNote ?? '',
    encounterId: soap?.encounterId ?? encounter?.encounterId ?? '',
    pharmacyNote: soap?.pharmacyNote ?? '',
    practitionerId: soap?.practitionerId ?? encounter?.practitioner?.id ?? '',
    // encounterVisitPlan: {
    //   note: soap?.encounterVisitPlan?.note ?? '',
    //   visitPlanDate: soap?.encounterVisitPlan?.visitPlanDate ?? '',
    // },
    soapMedicationIcd9Requests: soap?.soapMedicationIcd9s.map((item) => ({
      icd9: item?.icd9 ?? '',
    })) ?? [
      {
        icd9: '',
      },
    ],
    soapMedicationProcedures:
      soap?.soapMedicationProcedures && !isEmpty(soap?.soapMedicationProcedures)
        ? soap?.soapMedicationProcedures.map((item) => ({
            basePrice: item?.basePrice ?? 0,
            disc: item?.disc ?? 0,
            feePercentage: item?.feePercentage ?? 0,
            note: item?.note ?? '',
            practitionerId: item?.practitionerId ?? encounter?.practitioner?.id ?? '',
            practitionerName: item?.practitionerName ?? encounter?.practitioner?.name ?? '',
            procedureId: item?.procedureId ?? '',
            procedureName: item?.procedureName ?? '',
            qty: item?.qty ?? 1,
          }))
        : [
            {
              basePrice: 0,
              disc: 0,
              feePercentage: 0,
              note: '',
              practitionerId: soap?.practitionerId ?? encounter?.practitioner?.id ?? '',
              practitionerName: soap?.practitionerName ?? encounter?.practitioner?.name ?? '',
              procedureId: '',
              procedureName: '',
              qty: 1,
            },
          ],
    soapMedicationRequests:
      soap?.soapMedicationRequests && !isEmpty(soap?.soapMedicationRequests)
        ? soap?.soapMedicationRequests.map((item) => ({
            disc: item?.disc ?? 0,
            frequency: item?.frequency ?? 1,
            materialId: item?.materialId ?? '',
            medicineRouteCode: item?.medicineRouteCode ?? '',
            note: item?.note ?? '',
            periodTimeCode: item?.periodTimeCode ?? '',
            periodUnitCode: item?.periodUnitCode ?? '',
            qty: item?.qty ?? 1,
            salePrice: item?.salePrice ?? 0,
            signaQty: item?.signaQty ?? 1,
          }))
        : [
            {
              disc: 0,
              frequency: 1,
              materialId: '',
              medicineRouteCode: '',
              note: '',
              periodTimeCode: '',
              periodUnitCode: 'PERIOD_UNIT_05',
              qty: 1,
              salePrice: 0,
              signaQty: 1,
            },
          ],
  };

  return result;
};
