import { IReqPCarePendaftaran } from 'src/@types/bpjs-pcare';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/bpjs/api/pcare/pendaftaran';

const pcarePendaftaranApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    createPendaftaran: build.mutation<IReqPCarePendaftaran, IReqPCarePendaftaran>({
      query: (data) => ({
        url: `${prefix}/create`,
        method: 'POST',
        data,
      }),
    }),
  }),
});

pcarePendaftaranApi.enhanceEndpoints({
  addTagTypes: ['PCarePendaftaran'],
});

export const { useCreatePendaftaranMutation } = pcarePendaftaranApi;
