import {
  IPractitionerSchedule,
  IPractitionerScheduleLabelValues,
  IPractitionerScheduleLocationlabelValues,
  IPractitionerSchedulePrepare,
  IReqPractitionerSchedule,
} from 'src/@types/practitioner-schedule';
import { getProviderId } from 'src/utils/local-storage';
import { ILabelValue } from 'src/@types/base';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/practitioner/schedule';

const practitionerScheduleApi = baseKemedisApi.enhanceEndpoints({
  addTagTypes: [`${prefix}`, 'PractitionerSchedule', 'Practitioners'],
});

type ILocationIdPractitionerId = {
  locationId: string;
  practitionerId: string;
};

const _apiWithTaggedEndpoint = practitionerScheduleApi.injectEndpoints({
  endpoints: (build) => ({
    getPractitionerScheduleById: build.query<IPractitionerSchedule, ILocationIdPractitionerId>({
      query: ({ locationId, practitionerId }) => ({
        url: `${prefix}/${practitionerId}/${locationId}`,
        method: 'GET',
      }),
      providesTags: ['/services/arjuna/api/kemedis/practitioner/schedule'],
    }),
    createPractitionerSchedule: build.mutation<IPractitionerSchedule, IReqPractitionerSchedule>({
      query: (data) => ({
        url: `${prefix}`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        '/services/arjuna/api/kemedis/practitioner/schedule',
        'PractitionerSchedule',
        'Practitioners',
      ],
    }),
    getPractitionerSchedulePrepare: build.query<IPractitionerSchedulePrepare, string>({
      query: (id) => ({
        url: `${prefix}/prepare/edit/${id}`,
        method: 'GET',
      }),
      providesTags: ['/services/arjuna/api/kemedis/practitioner/schedule'],
    }),
    getLocationScheduleLabelValues: build.query<IPractitionerScheduleLocationlabelValues, unknown>({
      query: () => ({
        url: `${prefix}/location/labelvalues/${getProviderId()}`,
        method: 'GET',
      }),
      providesTags: ['/services/arjuna/api/kemedis/practitioner/schedule'],
    }),
    getPractitionerScheduleLabelValues: build.query<IPractitionerScheduleLabelValues, string>({
      query: (id) => ({
        url: `${prefix}/labelvalues/${id}`,
        method: 'GET',
      }),
      providesTags: ['/services/arjuna/api/kemedis/practitioner/schedule'],
    }),
  }),
});

export const {
  useGetPractitionerScheduleByIdQuery,
  useCreatePractitionerScheduleMutation,
  useGetPractitionerSchedulePrepareQuery,
  useGetLocationScheduleLabelValuesQuery,
  useGetPractitionerScheduleLabelValuesQuery,
} = _apiWithTaggedEndpoint;
