import {
  IReqIHS,
  IReqIHSCreateNewPatientSatuSehat,
  IReqIHSPostEncounter,
  IResCreateNewPatientSatuSehat,
  IResIHS,
} from 'src/@types/ihs';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/ihs-api/patient';

const ihsApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    postIHSApi: build.mutation<IResIHS, IReqIHS>({
      query: (data) => ({
        url: `${prefix}`,
        method: 'POST',
        data,
      }),
    }),
    createNewPatientSatuSehat: build.mutation<
      IResCreateNewPatientSatuSehat,
      IReqIHSCreateNewPatientSatuSehat
    >({
      query: (data) => ({
        url: `${prefix}/save`,
        method: 'POST',
        data,
      }),
    }),
    createEncounterSatuSehat: build.mutation<string, IReqIHSPostEncounter>({
      query: (data) => ({
        url: `${prefix}/encounter/save`,
        method: 'POST',
        data,
      }),
    }),
  }),
});

export const {
  usePostIHSApiMutation,
  useCreateNewPatientSatuSehatMutation,
  useCreateEncounterSatuSehatMutation,
} = ihsApi;
