import { getProviderId } from 'src/utils/local-storage';
import { normalizeParams } from 'src/utils/convertFilterString';
import { IPagination, IReqGlobalRsql } from 'src/@types/base';
import { IAppointment, IReqAppointment, IReqCancelAppointment } from 'src/@types/appointment';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/appointment';

export const appointmentApi = baseKemedisApi.enhanceEndpoints({
  addTagTypes: [`${prefix}`, 'Appointment'],
});

const _apiWithTaggedEndpoint = appointmentApi.injectEndpoints({
  endpoints: (build) => ({
    getAppointments: build.query<IPagination<IAppointment>, Partial<IReqGlobalRsql>>({
      query: (params) => ({
        url: `${prefix}/providers/${getProviderId()}`,
        method: 'GET',
        params: normalizeParams(params),
      }),
      providesTags: [`${prefix}`, 'Appointment'],
    }),
    getAppointmentById: build.query<IAppointment, string>({
      query: (id) => ({
        url: `${prefix}/${id}`,
        method: 'GET',
      }),
      providesTags: [`${prefix}`, 'Appointment'],
    }),
    createAppointment: build.mutation<IAppointment, IReqAppointment>({
      query: (data) => ({
        url: `${prefix}`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [`${prefix}`, 'Appointment'],
    }),
    cancelAppointment: build.mutation<IAppointment, IReqCancelAppointment>({
      query: (data) => ({
        url: `${prefix}/cancel`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [`${prefix}`, 'Appointment'],
    }),
  }),
});

export const {
  useGetAppointmentsQuery,
  useGetAppointmentByIdQuery,
  useCreateAppointmentMutation,
  useCancelAppointmentMutation,
} = _apiWithTaggedEndpoint;
