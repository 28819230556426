import { IReqVClaimMember, IVClaimMember } from 'src/@types/v-claim-member';
import { getProviderId } from 'src/utils/local-storage';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/bpjs/api/vclaim/member';

const vClaimMemberApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    getMemberCard: build.query<IVClaimMember, IReqVClaimMember>({
      query: (req) => ({
        url: `${prefix}/card/${getProviderId()}/${req.cardId}/${req.tglsep}`,
        method: 'GET',
      }),
    }),
    getMemberCardByNIK: build.query<IVClaimMember, IReqVClaimMember>({
      query: (req) => ({
        url: `${prefix}/nik/${getProviderId()}/${req.cardId}/${req.tglsep}`,
        method: 'GET',
      }),
    }),
  }),
});

vClaimMemberApi.enhanceEndpoints({
  addTagTypes: ['VClaimMember'],
  endpoints: {
    getMemberCard: {
      providesTags: ['VClaimMember'],
    },
    getMemberCardByNIK: {
      providesTags: ['VClaimMember'],
    },
  },
});

export const {
  useGetMemberCardQuery,
  useGetMemberCardByNIKQuery,
  useLazyGetMemberCardByNIKQuery,
  useLazyGetMemberCardQuery,
} = vClaimMemberApi;
