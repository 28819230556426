import {
  IPrepareProcedureReport,
  IProcedureReportJson,
  IProcedureReportList,
} from 'src/@types/procedure-report';
import { IReqGlobalRsql } from 'src/@types/base';
import { getProviderId } from 'src/utils/local-storage';
import { normalizeParams } from 'src/utils/convertFilterString';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/report/procedure-reports';

const procedureReportApi = baseKemedisApi.enhanceEndpoints({
  addTagTypes: [`${prefix}`],
});

const _apiWithTaggedEndpoint = procedureReportApi.injectEndpoints({
  endpoints: (build) => ({
    getProcedureReports: build.query<IProcedureReportList, Partial<IReqGlobalRsql>>({
      query: (reqRsql) => ({
        url: `${prefix}/${getProviderId()}`,
        method: 'GET',
        params: normalizeParams(reqRsql),
      }),
      providesTags: ['/services/arjuna/api/kemedis/report/procedure-reports'],
    }),
    getProcedureReportJson: build.query<IProcedureReportJson, Partial<IReqGlobalRsql>>({
      query: (reqRsql) => ({
        url: `${prefix}/json/${getProviderId()}`,
        method: 'GET',
        params: normalizeParams(reqRsql),
      }),
      providesTags: ['/services/arjuna/api/kemedis/report/procedure-reports'],
    }),
    getProcedureReportPrepare: build.query<IPrepareProcedureReport, unknown>({
      query: () => ({
        url: `${prefix}/prepare/search/${getProviderId()}`,
        method: 'GET',
      }),
      providesTags: ['/services/arjuna/api/kemedis/report/procedure-reports'],
    }),
  }),
});

export const {
  useGetProcedureReportsQuery,
  useGetProcedureReportJsonQuery,
  useGetProcedureReportPrepareQuery,
} = _apiWithTaggedEndpoint;
