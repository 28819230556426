import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------
// ACCOUNT
export const AccountActivatePage = Loadable(lazy(() => import('../pages/account/ActivatePage')));

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const OnboardingPage = Loadable(lazy(() => import('../pages/auth/OnboardingPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));
export const ResetSuccessPage = Loadable(lazy(() => import('../pages/auth/ResetSuccessPage')));

// DASHBOARD: GENERAL
export const GeneralAppPage = Loadable(lazy(() => import('../pages/dashboard/GeneralAppPage')));
export const FeaturesInitPage = Loadable(
  lazy(() => import('../pages/dashboard/features/features-init-page'))
);

// BLANK PAGE
export const BlankPage = Loadable(lazy(() => import('../pages/dashboard/BlankPage')));

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));

// DASHBOARD KEMEDIS

// master ihs

// master ihs - location
export const MasterIhsLocation = Loadable(
  lazy(() => import('../pages/dashboard/Master/Ihs/Location/MasterIhsLocation'))
);

export const MasterIhsLocationCreate = Loadable(
  lazy(() => import('../pages/dashboard/Master/Ihs/Location/MasterIhsLocationCreate'))
);

export const MasterIhsLocationUpdate = Loadable(
  lazy(() => import('../pages/dashboard/Master/Ihs/Location/MasterIhsLocationUpdate'))
);
// end master ihs - location

// master ihs - practitioner
export const MasterIhsPractitioner = Loadable(
  lazy(() => import('../pages/dashboard/Master/Ihs/Practitioner/MasterIhsPractitioner'))
);
export const MasterIhsPractitionerCreate = Loadable(
  lazy(() => import('../pages/dashboard/Master/Ihs/Practitioner/MasterIhsPractitionerCreate'))
);
export const MasterIhsPractitionerUpdate = Loadable(
  lazy(() => import('../pages/dashboard/Master/Ihs/Practitioner/MasterIhsPractitionerUpdate'))
);
export const MasterIhsPractitionerSchedule = Loadable(
  lazy(() => import('../pages/dashboard/Master/Ihs/Practitioner/MasterIhsPractitionerSchedule'))
);
// end master ihs - practitioner

// end master ihs

// master pharmacy
// master pharmacy - medicine input
export const MasterPharmacyMedicineInput = Loadable(
  lazy(() => import('../pages/dashboard/Master/Pharmacy/MedicineInput/MasterPharmacyMedicineInput'))
);
export const MasterPharmacyMedicineInputBrandCreate = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/Master/Pharmacy/MedicineInput/Brand/MasterPharmacyMedicineInputBrandCreate'
      )
  )
);
export const MasterPharmacyMedicineInputBrandUpdate = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/Master/Pharmacy/MedicineInput/Brand/MasterPharmacyMedicineInputBrandUpdate'
      )
  )
);
// end master pharmacy - medicine input

// master pharmacy - medicine
export const MasterPharmacyMedicineInputMedicineCreate = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/Master/Pharmacy/MedicineInput/Medicine/MasterPharmacyMedicineInputMedicineCreate'
      )
  )
);

export const MasterPharmacyMedicineInputMedicineUpdate = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/Master/Pharmacy/MedicineInput/Medicine/MasterPharmacyMedicineInputMedicineUpdate'
      )
  )
);
// end master pharmacy - medicine

// master pharmacy - medical device
export const MasterPharmacyMedicineInputMedicalDeviceCreate = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/Master/Pharmacy/MedicineInput/MedicalDevice/MasterPharmacyMedicineInputMedicalDeviceCreate'
      )
  )
);

export const MasterPharmacyMedicineInputMedicalDeviceUpdate = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/Master/Pharmacy/MedicineInput/MedicalDevice/MasterPharmacyMedicineInputMedicalDeviceUpdate'
      )
  )
);
// end master pharmacy - medical device
// end master pharmacy

// master product and services
// master product and services - product
export const MasterProductServicesProduct = Loadable(
  lazy(() => import('../pages/dashboard/Master/ProductService/Product/MasterProductServiceProduct'))
);
export const MasterProductServicesProductCreate = Loadable(
  lazy(
    () =>
      import('../pages/dashboard/Master/ProductService/Product/MasterProductServiceProductCreate')
  )
);
export const MasterProductServicesProductUpdate = Loadable(
  lazy(
    () =>
      import('../pages/dashboard/Master/ProductService/Product/MasterProductServiceProductUpdate')
  )
);
// end master product and services - product
// end master product and services

// master guarantor
export const MasterGuarantor = Loadable(
  lazy(() => import('../pages/dashboard/Master/Guarantor/MasterGuarantor'))
);
export const MasterGuarantorCreate = Loadable(
  lazy(() => import('../pages/dashboard/Master/Guarantor/MasterGuarantorCreate'))
);
export const MasterGuarantorUpdate = Loadable(
  lazy(() => import('../pages/dashboard/Master/Guarantor/MasterGuarantorUpdate'))
);
// end master guarantor

// end master

// management
// management - employees
export const ManagementEmployeesListPage = Loadable(
  lazy(() => import('../pages/dashboard/Management/Employees/ManagementEmployeesListPage'))
);
export const ManagementEmployeesCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/Management/Employees/ManagementEmployeesCreatePage'))
);
export const ManagementEmployeesUpdatePage = Loadable(
  lazy(() => import('../pages/dashboard/Management/Employees/ManagementEmployeesUpdatePage'))
);
// end management - employees

// management roles
export const ManagementRolesListPage = Loadable(
  lazy(() => import('../pages/dashboard/Management/Roles/ManagementRolesListPage'))
);
// end management roles

// management - permissions
export const ManagementPermissionsListPage = Loadable(
  lazy(() => import('../pages/dashboard/Management/Permissions/ManagementPermissionsListPage'))
);
export const ManagementPermissionsCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/Management/Permissions/ManagementPermissionsCreatePage'))
);
export const ManagementPermissionsUpdatePage = Loadable(
  lazy(() => import('../pages/dashboard/Management/Permissions/ManagementPermissionsUpdatePage'))
);
// end management - permissions

// end management

// inventory
// inventory - stock medicines
export const InventoryStockMedicinesListPage = Loadable(
  lazy(() => import('../pages/dashboard/Inventory/StockMedicines/InventoryStockMedicinesListPage'))
);
// end inventory - stock medicines

// inventory - adjust stock medicines
export const InventoryAdjustStockMedicinesListPage = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/Inventory/AdjustStockMedicines/InventoryAdjustStockMedicinesListPage'
      )
  )
);
export const InventoryAdjustStockMedicinesCreatePage = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/Inventory/AdjustStockMedicines/InventoryAdjustStockMedicinesCreatePage'
      )
  )
);
export const InventoryAdjustStockMedicinesUpdatePage = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/Inventory/AdjustStockMedicines/InventoryAdjustStockMedicinesUpdatePage'
      )
  )
);
// end inventory - adjust stock medicines

// inventory - material receipt
export const InventoryMaterialReceiptListPage = Loadable(
  lazy(() => import('../pages/dashboard/Inventory/MaterialReceipt/InventoryMaterialReceiptList'))
);
export const InventoryMaterialReceiptCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/Inventory/MaterialReceipt/InventoryMaterialReceiptCreate'))
);
// end inventory - material receipt
// end inventory

// pharmacy service
// pharmacy service - e-prescribing
export const PharmacyServiceEPrescribing = Loadable(
  lazy(() => import('../pages/dashboard/PharmacyService/EPrescribing/PharmacyServiceEPrescribing'))
);
export const PharmacyServiceEPrescribingVerify = Loadable(
  lazy(
    () =>
      import('../pages/dashboard/PharmacyService/EPrescribing/PharmacyServiceEPrescribingVerify')
  )
);
export const PharmacyServiceEPrescribingUpdate = Loadable(
  lazy(
    () =>
      import('../pages/dashboard/PharmacyService/EPrescribing/PharmacyServiceEPrescribingUpdate')
  )
);
export const PharmacyServiceEPrescribingSubmission = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/PharmacyService/EPrescribing/PharmacyServiceEPrescribingSubmission'
      )
  )
);
// end pharmacy service - e-prescribing
// end pharmacy service

// === SECTION : CASHIER
// general
export const CashierGeneral = Loadable(
  lazy(() => import('../pages/dashboard/Cashier/General/CashierGeneral'))
);
export const CashierGeneralCreate = Loadable(
  lazy(() => import('../pages/dashboard/Cashier/General/CashierGeneralCreate'))
);
export const CashierGeneralView = Loadable(
  lazy(() => import('../pages/dashboard/Cashier/General/CashierGeneralView'))
);
export const CashierGeneralPrintThermal = Loadable(
  lazy(() => import('../pages/dashboard/Cashier/General/CashierGeneralPrintThermal'))
);
// end general

// outpatient
export const CashierOutpatient = Loadable(
  lazy(() => import('../pages/dashboard/Cashier/Outpatient/CashierOutpatient'))
);
export const CashierOutpatientCreate = Loadable(
  lazy(() => import('../pages/dashboard/Cashier/Outpatient/CashierOutpatientCreate'))
);
export const CashierOutpatientPrintThermal = Loadable(
  lazy(() => import('../pages/dashboard/Cashier/Outpatient/CashierOutpatientPrintThermal'))
);
// end outpatient
// === END SECTION : CASHIER

// === SECTION : BPJS

// === START BPJS : MONITORING ===
export const BPJSMonitoring = Loadable(
  lazy(() => import('../pages/dashboard/BPJS/Vclaim/Monitoring/bpjs-vclaim-monitoring'))
);
// === END BPJS : MONITORING ===

// === START BPJS : SEP ===

export const BPJSSepListPage = Loadable(
  lazy(() => import('../pages/dashboard/BPJS/Vclaim/SEP/bpjs-sep-list-page'))
);

export const BPJSSepCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/BPJS/Vclaim/SEP/bpjs-vclaim-sep-create-page'))
);

// === END BPJS : SEP ===

// === END SECTION : BPJS

// === SECTION : ADMISSION ===

// === START ADMISSION : BOOKING ===
export const AdmissionBooking = Loadable(
  lazy(() => import('../pages/dashboard/admission/booking/booking-list-page'))
);
export const AdmissionBookingCreate = Loadable(
  lazy(() => import('../pages/dashboard/admission/booking/booking-create-page'))
);
export const AdmissionBookingConfirmation = Loadable(
  lazy(() => import('../pages/dashboard/admission/booking/booking-confirmation-page'))
);
export const AdmissionBookingAfterConfirmation = Loadable(
  lazy(() => import('../pages/dashboard/admission/booking/booking-after-confirmation-page'))
);
export const AdmissionBookingSearchExistingPage = Loadable(
  lazy(() => import('../pages/dashboard/admission/booking/booking-search-existing-page'))
);
export const AdmissionBookingCreateExistingPage = Loadable(
  lazy(() => import('../pages/dashboard/admission/booking/booking-create-existing-page'))
);
// === END ADMISSION : BOOKING ===

// ======== START ADMISSION - PATIENT RESGISTRATION ========
export const AdmissionPatientRegistrationListPage = Loadable(
  lazy(
    () => import('../pages/dashboard/admission/patient-registration/patient-registration-list-page')
  )
);
// new
export const AdmissionPatientRegistrationCreatePage = Loadable(
  lazy(
    () =>
      import('../pages/dashboard/admission/patient-registration/patient-registration-create-page')
  )
);
// update
export const AdmissionPatientRegistrationUpdatePage = Loadable(
  lazy(
    () =>
      import('../pages/dashboard/admission/patient-registration/patient-registration-update-page')
  )
);
// existing
export const AdmissionPatientRegistrationSearchExistingPage = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/admission/patient-registration/patient-registration-search-existing-page'
      )
  )
);
export const AdmissionPatientRegistrationCreateExistingPage = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/admission/patient-registration/patient-registration-create-existing-page'
      )
  )
);

// sep

export const AdmissionPatientRegistrationCreateSepPage = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/admission/patient-registration/patient-registration-create-sep-page'
      )
  )
);

export const AdmissionPatientRegistrationUpdateSepPage = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/admission/patient-registration/patient-registration-update-sep-page'
      )
  )
);

export const AdmissionPatientRegistrationCreatePenjaminanPage = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/admission/patient-registration/patient-registration-create-penjaminan-page'
      )
  )
);

// sep pcare
export const AdmissionPatientRegistrationCreateSepPcarePage = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/admission/patient-registration/patient-registration-create-sep-pcare-page'
      )
  )
);
// end sep pcare

// visit plan
export const AdmissionPatientRegistrationCreateVisitPlanPage = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/admission/patient-registration/patient-registration-create-visit-plan'
      )
  )
);

export const AdmissionPatientRegistrationUpdateVisitPlanPage = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/admission/patient-registration/patient-registration-update-visitplan'
      )
  )
);

export const AdmissionPatientRegistrationCreateVisitPlanNonBPJSPage = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/admission/patient-registration/patient-registration-create-visit-plan-nonbpjs'
      )
  )
);

// rujukan

export const AdmissionPatientRegistrationCreateRujukanPage = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/admission/patient-registration/patient-registration-create-rujukan-page'
      )
  )
);

export const AdmissionPatientRegistrationUpdateRujukanPage = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/admission/patient-registration/patient-registration-update-rujukan-page'
      )
  )
);
// ======== END ADMISSION - RESGISTRATION PATIENT ========

// ======== START ADMISSION - PATIENT DATA ========
export const AdmissionPatientDataListPage = Loadable(
  lazy(() => import('../pages/dashboard/admission/patient-data/patient-data-list-page'))
);
export const AdmissionPatientDataCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/admission/patient-data/patient-data-create-page'))
);
export const AdmissionPatientDataUpdatePage = Loadable(
  lazy(() => import('../pages/dashboard/admission/patient-data/patient-data-update-page'))
);
// ======== END ADMISSION - PATIENT DATA ========
// === END SECTION : ADMISSION ===

// === SECTION : REPORTS ===
// ======== START REPORTS - MEDICINE-SALE ========
export const ReportsMaterialSale = Loadable(
  lazy(() => import('../pages/dashboard/Report/MaterialSale/ReportMaterialSale'))
);
// ======== END REPORTS - MEDICINE-SALE ========
// ======== START REPORT - PROCEDURE-SERVICE ========
export const ReportsProcedureService = Loadable(
  lazy(() => import('../pages/dashboard/Report/ProcedureService/ProcedureService'))
);
// ======== END REPORT - PROCEDURE-SERVICE ========
// === END SECTION : REPORTS ===

// SECTION SERVICE
// SECTION SERVICE - OUTPATIENT
export const ServiceOutpatientListPage = Loadable(
  lazy(() => import('../pages/dashboard/Service/Outpatient/ServiceOutpatientListPage'))
);
export const ServiceOutpatientUpdatePage = Loadable(
  lazy(() => import('../pages/dashboard/Service/Outpatient/ServiceOutpatientUpdatePage'))
);
export const ServiceOutpatientInitAssessmentPage = Loadable(
  lazy(() => import('../pages/dashboard/Service/Outpatient/ServiceOutpatientInitAssessmentPage'))
);
export const ServiceOutpatientIcd10Page = Loadable(
  lazy(() => import('../pages/dashboard/Service/Outpatient/ServiceOutpatientIcd10Page'))
);
// END SECTION SERVICE - OUTPATIENT

// SECTION SERVICE - INPATIENT
export const ServiceInpatientListPage = Loadable(
  lazy(() => import('../pages/dashboard/Service/Inpatient/ServiceInpatientListPage'))
);
// END SECTION SERVICE - INPATIENT
// END SECTION SERVICE

// SECTION INTEGRATIONS
// SECTION INTEGRATIONS - BPJS QUEUE
export const IntegrationsBpjsQueue = Loadable(
  lazy(() => import('../pages/dashboard/Integrations/BPJS-Queue/IntegrationsBPJSQueue'))
);
export const IntegrationsBpjsQueueCreate = Loadable(
  lazy(() => import('../pages/dashboard/Integrations/BPJS-Queue/IntegrationsBPJSQueueCreate'))
);
// END SECTION INTEGRATIONS - BPJS QUEUE

// SECTION INTEGRATIONS - BPJS PCare
export const IntegrationsBpjsPCare = Loadable(
  lazy(() => import('../pages/dashboard/Integrations/BPJS-PCare/IntegrationsBPJSPCare'))
);
export const IntegrationsBpjsPCareCreate = Loadable(
  lazy(() => import('../pages/dashboard/Integrations/BPJS-PCare/IntegrationsBPJSPCareCreate'))
);
// END SECTION INTEGRATIONS - BPJS PCare

// SECTION INTEGRATIONS - BPJS VClaim
export const IntegrationsBpjsVClaim = Loadable(
  lazy(() => import('../pages/dashboard/Integrations/BPJS-VClaim/IntegrationsBPJSVClaim'))
);
export const IntegrationsBpjsVClaimCreate = Loadable(
  lazy(() => import('../pages/dashboard/Integrations/BPJS-VClaim/IntegrationsBPJSVClaimCreate'))
);
// END SECTION INTEGRATIONS - BPJS VClaim

// SECTION INTEGRATIONS - SATU SEHAT
export const IHSNumberPage = Loadable(
  lazy(() => import('../pages/dashboard/Integrations/IHS-Number/IntegrationIHSNumberPage'))
);
export const IHSNumberCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/Integrations/IHS-Number/IntegrationIHSNumberCreatePage'))
);
// END SECTION INTEGRATIONS - SATU SEHAT
// END SECTION INTEGRATIONS

// END DASHBOARD KEMEDIS
