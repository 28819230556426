// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Autocomplete, AutocompleteProps, OutlinedInputProps, TextField } from '@mui/material';
// ----------------------------------------------------------------------

interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  name: string;
  label?: string;
  withAsterisk?: boolean;
  helperText?: React.ReactNode;
  placeholder?: string;
  InputProps?: Partial<OutlinedInputProps>;
}

export default function RHFAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  name,
  label,
  withAsterisk,
  helperText,
  placeholder,
  InputProps,
  ...other
}: Omit<Props<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'>) {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          onChange={(event, newValue) => setValue(name, newValue, { shouldValidate: true })}
          renderInput={(params) => (
            <TextField
              placeholder={placeholder}
              error={!!error}
              helperText={error ? error?.message : helperText}
              {...params}
              size={params.size ?? 'small'}
              label={
                withAsterisk ? (
                  <>
                    {label} <span style={{ color: 'red' }}>*</span>
                  </>
                ) : (
                  <>{label}</>
                )
              }
              // InputProps={InputProps}
            />
          )}
          {...other}
        />
      )}
    />
  );
}
