import { CLOUDINARY_CLOUD_URL } from 'src/config-global';
import axiosInstance from 'src/utils/axios-kemedis';
import { IResCloudinary } from 'src/@types/cloudinary';

export const uploadImage = async (file: File): Promise<IResCloudinary> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('api_key', '868343132692299');
  formData.append('upload_preset', 'gallery_upload');

  try {
    const response = await axiosInstance.post<IResCloudinary>(
      `${CLOUDINARY_CLOUD_URL}/image/upload`,
      formData
    );

    return response.data;
  } catch (error) {
    console.log('error: ', error);
    return error;
  }
};
