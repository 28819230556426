// https://api.kemedik.com/api/account/reset-password/init
import { IReqNewPassword, IReqResetPassword } from 'src/@types/account';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/api/account';

const accountApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    resetPassword: build.mutation<IReqResetPassword, IReqResetPassword>({
      query: (data) => ({
        url: `${prefix}/reset-password/init`,
        method: 'POST',
        data,
      }),
    }),
    newPassword: build.mutation<IReqNewPassword, IReqNewPassword>({
      query: (data) => ({
        url: `${prefix}/reset-password/finish`,
        method: 'POST',
        data,
      }),
    }),
    getActivate: build.query<string, string>({
      query: (key) => ({
        url: `/account/activate`,
        method: 'GET',
        params: { key },
      }),
    }),
  }),
});

export const { useResetPasswordMutation, useNewPasswordMutation, useGetActivateQuery } = accountApi;
