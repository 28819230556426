// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard,
        roles: ['dashboard'],
      },
    ],
  },

  // KEMEDIS

  // master
  {
    subheader: '',
    items: [
      {
        title: 'master',
        path: PATH_DASHBOARD.master.root,
        icon: ICONS.file,
        roles: ['master'],
        children: [
          // {
          //   title: 'ihs',
          //   path: PATH_DASHBOARD.master.ihs.root,
          //   roles: ['master-ihs'],
          //   children: [
          {
            title: 'lokasi',
            path: PATH_DASHBOARD.master.ihs.location.root,
            roles: ['master-ihs-location'],
          },
          {
            title: 'tenaga medis',
            path: PATH_DASHBOARD.master.ihs.practitioner.root,
            roles: ['master-ihs-practitioner'],
          },
          //   ],
          // },
          {
            title: 'depo farmasi',
            roles: ['master-farmasi'],
            path: PATH_DASHBOARD.master.pharmacy.root,
            children: [
              {
                title: 'obat dan alkes',
                path: PATH_DASHBOARD.master.pharmacy.medicineInput.root,
                roles: ['master-farmasi-medicine'],
              },
            ],
          },
          {
            title: 'produk dan layanan',
            roles: ['master-product-service'],
            path: PATH_DASHBOARD.master.productAndService.root,
            children: [
              {
                title: 'data produk',
                path: PATH_DASHBOARD.master.productAndService.product.root,
                roles: ['master-product-service-product'],
              },
            ],
          },
          {
            title: 'asuransi dan data perusahaan',
            path: PATH_DASHBOARD.master.guarantor.root,
            roles: ['master-guarantor'],
          },
        ],
      },
    ],
  },
  // end master

  // management
  {
    subheader: '',
    items: [
      // employees
      {
        title: 'Manajemen',
        path: PATH_DASHBOARD.management.root,
        icon: ICONS.user,
        roles: ['management'],
        children: [
          {
            title: 'Karyawan',
            path: PATH_DASHBOARD.management.employees.root,
            roles: ['management-employee'],
          },
          { title: 'Role', path: PATH_DASHBOARD.management.roles.root, roles: ['management-role'] },
          {
            title: 'Hak Akses',
            path: PATH_DASHBOARD.management.permissions.root,
            roles: ['management-user'],
          },
        ],
      },
      // end employees
    ],
  },
  // end management

  // inventory
  {
    subheader: '',
    items: [
      {
        title: 'Persediaan',
        path: PATH_DASHBOARD.inventory.root,
        icon: ICONS.folder,
        roles: ['inventory'],
        children: [
          {
            title: 'Stok Obat/Alkes',
            path: PATH_DASHBOARD.inventory.stockMedicines.root,
            roles: ['inventory-medicine'],
          },
          {
            title: 'Penyesuaian Stok Obat/Alkes',
            path: PATH_DASHBOARD.inventory.adjustStockMedicines.root,
            roles: ['inventory-medicine-adj'],
          },
          {
            title: 'Penerimaan Obat/Alkes',
            path: PATH_DASHBOARD.inventory.materialReceipt.root,
            // roles: ['inventory-medicine-receipt'],
          },
        ],
      },
    ],
  },
  // end inventory

  // admission
  {
    subheader: '',
    items: [
      {
        title: 'Admisi',
        path: PATH_DASHBOARD.admission.root,
        icon: ICONS.banking,
        roles: ['admission'],
        children: [
          {
            title: 'Booking',
            path: PATH_DASHBOARD.admission.booking.root,
            // roles: ['admission-patient'],
          },
          {
            title: 'Pendaftaran Pasien',
            path: PATH_DASHBOARD.admission.patientRegistration.root,
            roles: ['admission-patient'],
          },
          {
            title: 'Data Pasien',
            path: PATH_DASHBOARD.admission.patientData.root,
            roles: ['admission-patient-data'],
          },
        ],
      },
    ],
  },
  // end admission

  // services
  {
    subheader: '',
    items: [
      {
        title: 'layanan pemeriksaan',
        path: PATH_DASHBOARD.services.root,
        icon: ICONS.kanban,
        roles: ['service'],
        children: [
          {
            title: 'Rawat Jalan',
            path: PATH_DASHBOARD.services.outpatient.root,
            roles: ['service-outpatient'],
          },
          {
            title: 'Rawat Inap',
            path: PATH_DASHBOARD.services.inpatient.root,
            roles: ['service-outpatient'],
          },
        ],
      },
    ],
  },
  // end services

  // pharmacy service
  {
    subheader: '',
    items: [
      {
        title: 'Layanan Farmasi',
        path: PATH_DASHBOARD.pharmacyService.root,
        icon: ICONS.ecommerce,
        roles: ['farmasi'],
        children: [
          {
            title: 'Resep Elektronik',
            path: PATH_DASHBOARD.pharmacyService.ePrescribing.root,
            roles: ['farmasi-eprescribing'],
          },
        ],
      },
    ],
  },
  // end pharmacy service

  // cashier
  {
    subheader: '',
    items: [
      {
        title: 'kasir / penjualan',
        path: PATH_DASHBOARD.cashier.root,
        icon: ICONS.invoice,
        roles: ['cashier'],
        children: [
          {
            title: 'kasir umum',
            path: PATH_DASHBOARD.cashier.general.root,
            roles: ['cashier-pharmacy'],
          },
          {
            title: 'kasir rawat jalan',
            path: PATH_DASHBOARD.cashier.outpatient.root,
            roles: ['cashier-public'],
          },
        ],
      },
    ],
  },
  // end cashier

  // integrations
  {
    subheader: '',
    items: [
      {
        title: 'integrasi',
        path: PATH_DASHBOARD.integrations.root,
        icon: ICONS.external,
        // roles: ['integrations'],
        children: [
          {
            title: 'BPJS',
            path: PATH_DASHBOARD.integrations.bpjs.root,
            // roles: ['integrations-bpjs'],
            children: [
              {
                title: 'BPJS Antrian',
                path: PATH_DASHBOARD.integrations.bpjs.queue.root,
                // roles: ['integrations-bpjs'],
              },
              {
                title: 'BPJS PCare',
                path: PATH_DASHBOARD.integrations.bpjs.pcare.root,
                // roles: ['integrations-pcare'],
              },
              {
                title: 'BPJS VClaim',
                path: PATH_DASHBOARD.integrations.bpjs.vclaim.root,
                // roles: ['integrations-vclaim'],
              },
            ],
          },
          {
            title: 'Satu Sehat',
            path: PATH_DASHBOARD.integrations.satuSehat.root,
            children: [
              {
                title: 'Nomor IHS',
                path: PATH_DASHBOARD.integrations.satuSehat.ihs.root,
              },
            ],
          },
        ],
      },
    ],
  },
  // end integrations

  // bpjs
  {
    subheader: '',
    items: [
      {
        title: 'SEP BPJS',
        path: PATH_DASHBOARD.bpjs.root,
        icon: ICONS.label,
        // roles: ['bpjs'],
        children: [
          {
            title: 'VClaim',
            path: PATH_DASHBOARD.bpjs.vclaim.root,
            // roles: ['bpjs-sep'],
            children: [
              {
                title: 'Monitoring',
                path: PATH_DASHBOARD.bpjs.vclaim.monitoring.root,
              },
              {
                title: 'Cetak SEP',
                path: PATH_DASHBOARD.bpjs.vclaim.sep.root,
                // roles: ['bpjs-sep'],
              },
            ],
          },
        ],
      },
    ],
  },
  // end bpjs

  // reports
  {
    subheader: '',
    items: [
      {
        title: 'laporan',
        path: PATH_DASHBOARD.reports.root,
        icon: ICONS.analytics,
        roles: ['report'],
        children: [
          {
            title: 'penjualan obat',
            path: PATH_DASHBOARD.reports.materialSale.root,
            roles: ['report-medicine-sales'],
          },
          {
            title: 'jasa layanan',
            path: PATH_DASHBOARD.reports.procedureService.root,
            roles: ['report-procedure-service'],
          },
        ],
      },
    ],
  },
  // end reports

  // END KEMEDIS
];

export default navConfig;
