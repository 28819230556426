import {
  IParamsRujukanList,
  IReqDeleteRujukan,
  IReqVClaimRujukan,
  IResRujukanList,
} from 'src/@types/vclaim-rujukan';
import { getProviderId } from 'src/utils/local-storage';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/bpjs/api/vclaim/rujukan';

const vClaimRujukanApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    getRujukanList: build.query<IResRujukanList, IParamsRujukanList>({
      query: (params) => ({
        url: `${prefix}/keluar/list/${getProviderId()}/${params.tglAwal}/${params.tglAkhir}`,
        method: 'GET',
      }),
    }),
    createRujukan: build.mutation<IReqVClaimRujukan, IReqVClaimRujukan>({
      query: (data) => ({
        url: `${prefix}`,
        method: 'POST',
        data,
      }),
    }),
    deleteRujukan: build.mutation<IReqVClaimRujukan, IReqDeleteRujukan>({
      query: (data) => ({
        url: `${prefix}/delete`,
        method: 'DELETE',
        data,
      }),
    }),
  }),
});

export const {
  useCreateRujukanMutation,
  useDeleteRujukanMutation,
  useGetRujukanListQuery,
  useLazyGetRujukanListQuery,
} = vClaimRujukanApi;
