import { getProviderId } from 'src/utils/local-storage';
import {
  IResSoapPrepareEdit,
  IReqSoapPrepareEdit,
  IReqSoap,
  IReqSoapPlan,
  IReqSoapUpload,
  ISoap,
} from 'src/@types/soap';
import { soaFormDefaultValue } from 'src/sections/@dashboard/service/outpatient/form/SoaForm/soa-form.default-value';
import { soapPlanDefaulValue } from 'src/sections/@dashboard/service/outpatient/form/PlanForm/soap-plan.default-value';
import { isEmpty } from 'lodash';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/soaps';

const soapApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    getSoapPrepareEdit: build.query<IResSoapPrepareEdit, IReqSoapPrepareEdit>({
      query: (params) => ({
        url: `${prefix}/prepare/edit/${params.encounterId}/${
          params.practitionerId
        }/${getProviderId()}`,
        method: 'GET',
      }),
    }),

    getSoapById: build.query<ISoap, string>({
      query: (id) => ({
        url: `${prefix}/${id}`,
        method: 'GET',
      }),
    }),

    createSoap: build.mutation<void, IReqSoap>({
      query: (data) => ({
        url: `${prefix}`,
        method: 'POST',
        data: {
          ...data,
          soapVitalSigns: data.soapVitalSigns.map((item) => {
            if (typeof item.numeratorValue === 'string') {
              if (item.numeratorValue.includes('/')) {
                const numeratorValue = item.numeratorValue.split('/');
                item.numeratorValue = Number(numeratorValue[0]) || 0;
                item.denominatorValue = Number(numeratorValue[1]) || 0;
              } else {
                item.numeratorValue = Number(item.numeratorValue) || 0;
              }
            }
            return item;
          }),
          odontogram: {
            ...data.odontogram,
            note: data.odontogram?.note || '',
            soapOdontogramConditions:
              data.odontogram?.soapOdontogramConditions.filter(
                (item) => !isEmpty(item?.conditionCode)
                // && !isEmpty(item?.surfaceCode)
              ) || [],
            soapOdontogramProtesas:
              data.odontogram?.soapOdontogramProtesas.filter(
                (item) => !isEmpty(item?.protesaCode)
                // && !isEmpty(item?.surfaceCode)
              ) || [],
            soapOdontogramRestorations:
              data.odontogram?.soapOdontogramRestorations.filter(
                (item) => !isEmpty(item?.materialCode)
                //   &&
                //   !isEmpty(item?.restorationCode) &&
                //   !isEmpty(item?.surfaceCode)
              ) || [],
          },
          soapAssessments:
            JSON.stringify(data.soapAssessments) ===
            JSON.stringify(soaFormDefaultValue().soapAssessments)
              ? []
              : data.soapAssessments,
        },
      }),
    }),

    createSoapPlan: build.mutation<void, IReqSoapPlan>({
      query: (data) => ({
        url: `${prefix}/plan`,
        method: 'POST',
        data: {
          ...data,
          soapMedicationIcd9Requests:
            JSON.stringify(data.soapMedicationIcd9Requests) ===
            JSON.stringify(soapPlanDefaulValue().soapMedicationIcd9Requests)
              ? []
              : data.soapMedicationIcd9Requests,
          soapMedicationProcedures:
            JSON.stringify(data.soapMedicationProcedures) ===
            JSON.stringify(soapPlanDefaulValue().soapMedicationProcedures)
              ? []
              : data.soapMedicationProcedures,
          soapMedicationRequests:
            JSON.stringify(data.soapMedicationRequests) ===
            JSON.stringify(soapPlanDefaulValue().soapMedicationRequests)
              ? []
              : data.soapMedicationRequests,
        },
      }),
    }),

    createSoapUpload: build.mutation<void, IReqSoapUpload>({
      query: (data) => ({
        url: `${prefix}/upload`,
        method: 'POST',
        data,
      }),
    }),
  }),
});

soapApi.enhanceEndpoints({
  addTagTypes: ['Soap', 'SoapPrepareEdit', 'Encounter', 'Bills'],
  endpoints: {
    getSoapPrepareEdit: {
      providesTags: ['SoapPrepareEdit'],
    },
    getSoapById: {
      providesTags: ['Soap'],
    },
    createSoap: {
      invalidatesTags: ['SoapPrepareEdit', 'Soap', 'Encounter', 'Bills'],
    },
    createSoapPlan: {
      invalidatesTags: ['SoapPrepareEdit', 'Soap', 'Encounter', 'Bills'],
    },
    createSoapUpload: {
      invalidatesTags: ['SoapPrepareEdit', 'Soap', 'Encounter', 'Bills'],
    },
  },
});

export const {
  useGetSoapPrepareEditQuery,
  useGetSoapByIdQuery,
  useCreateSoapMutation,
  useCreateSoapPlanMutation,
  useCreateSoapUploadMutation,
} = soapApi;
