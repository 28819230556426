import { IPrepareEditParamsSep, IReqVClaimMember, IVClaimMember } from 'src/@types/v-claim-member';
import { getProviderId } from 'src/utils/local-storage';
import {
  IParamsPenjaminList,
  IReqCreateSepPenjamin,
  IReqDeleteVClaimSep,
  IReqSepApproval,
  IReqVClaimSep,
  IReqVClaimSepUpdate,
  IResPenjaminList,
  IResVClaimSep,
  IResVClaimSepPrepareEdit,
} from 'src/@types/vclaim-sep';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/bpjs/api/vclaim/sep';

const vClaimSepApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    getPrepareEditSep: build.query<IResVClaimSepPrepareEdit, IPrepareEditParamsSep>({
      query: (req) => ({
        url: `${prefix}/prepare/edit/${req.encounterId}/${req.tglsep}`,
        method: 'GET',
      }),
    }),
    getVclaimSep: build.query<IResVClaimSep, string>({
      query: (noSep) => ({
        url: `${prefix}/${getProviderId()}/${noSep}`,
        method: 'GET',
      }),
    }),
    getSEPPenjaminan: build.query<IResPenjaminList, IParamsPenjaminList>({
      query: (data) => ({
        url: `${prefix}/penjaminan-sep/list/${getProviderId()}/${data.bulan}/${data.tahun}`,
        method: 'GET',
      }),
    }),
    createVClaimSep: build.mutation<IReqVClaimSep, IReqVClaimSep>({
      query: (data) => ({
        url: `${prefix}`,
        method: 'POST',
        data,
      }),
    }),
    updateVClaimSep: build.mutation<IReqVClaimSep, IReqVClaimSepUpdate>({
      query: (data) => ({
        url: `${prefix}`,
        method: 'PUT',
        data,
      }),
    }),
    deleteVClaimSep: build.mutation<IReqVClaimSep, IReqDeleteVClaimSep>({
      query: (data) => ({
        url: `${prefix}`,
        method: 'DELETE',
        data,
      }),
    }),
    createSepPenjaminan: build.mutation<IReqCreateSepPenjamin, IReqCreateSepPenjamin>({
      query: (data) => ({
        url: `${prefix}/penjaminan-sep`,
        method: 'POST',
        data,
      }),
    }),
    createApprovalSepPenjaminan: build.mutation<IReqSepApproval, IReqSepApproval>({
      query: (data) => ({
        url: `${prefix}/penjaminan-sep/approval`,
        method: 'POST',
        data,
      }),
    }),
  }),
});

vClaimSepApi.enhanceEndpoints({
  addTagTypes: ['VClaimSep'],
  endpoints: {
    getPrepareEditSep: {
      providesTags: ['VClaimSep'],
    },
    getVclaimSep: {
      providesTags: ['VClaimSep'],
    },
    getSEPPenjaminan: {
      providesTags: ['VClaimSep'],
    },
    createVClaimSep: {
      invalidatesTags: ['VClaimSep'],
    },
    updateVClaimSep: {
      invalidatesTags: ['VClaimSep'],
    },
    deleteVClaimSep: {
      invalidatesTags: ['VClaimSep'],
    },
    createSepPenjaminan: {
      invalidatesTags: ['VClaimSep'],
    },
    createApprovalSepPenjaminan: {
      invalidatesTags: ['VClaimSep'],
    },
  },
});

export const {
  useGetPrepareEditSepQuery,
  useGetVclaimSepQuery,
  useUpdateVClaimSepMutation,
  useLazyGetPrepareEditSepQuery,
  useLazyGetVclaimSepQuery,
  useGetSEPPenjaminanQuery,
  useLazyGetSEPPenjaminanQuery,
  useCreateVClaimSepMutation,
  useDeleteVClaimSepMutation,
  useCreateSepPenjaminanMutation,
  useCreateApprovalSepPenjaminanMutation,
} = vClaimSepApi;
