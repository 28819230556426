import { IPagination, IReqGlobalRsql, ILabelValue } from 'src/@types/base';
import { normalizeParams } from 'src/utils/convertFilterString';
import { IICd9 } from 'src/@types/icd9';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/icd9s';

const icd9Api = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    // get /api/kemedis/icd10s
    getIcd9LabelValue: build.query<ILabelValue[], Partial<IReqGlobalRsql>>({
      query: (reqRsql) => ({
        url: `${prefix}`,
        method: 'GET',
        params: normalizeParams(reqRsql),
      }),
      transformResponse: (res: IPagination<IICd9>) =>
        res.content.map((item) => ({
          label: `${item.id} - ${item.icdName}`,
          value: item.id,
          description: item.description,
        })),
    }),
  }),
});

export const { useGetIcd9LabelValueQuery } = icd9Api;
