import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link, BoxProps } from '@mui/material';
import { LogoKemedisFull, KemedisLogoMini } from 'src/assets/illustrations';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  mode?: 'mini' | 'full';
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, mode = 'full', sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 40,
          height: 40,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <svg
          width="230"
          height="230"
          viewBox="0 0 230 230"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
              <stop offset="0%" stopColor={PRIMARY_DARK} />
              <stop offset="100%" stopColor={PRIMARY_MAIN} />
            </linearGradient>

            <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
              <stop offset="0%" stopColor={PRIMARY_LIGHT} />
              <stop offset="100%" stopColor={PRIMARY_MAIN} />
            </linearGradient>

            <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
              <stop offset="0%" stopColor={PRIMARY_LIGHT} />
              <stop offset="100%" stopColor={PRIMARY_MAIN} />
            </linearGradient>
          </defs>

          <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
            <path
              d="M230 86.4825C230 99.322 219.592 109.73 206.752 109.73C193.913 109.73 183.504 99.322 183.504 86.4825C183.504 73.643 193.913 63.2345 206.752 63.2345C219.592 63.2345 230 73.643 230 86.4825Z"
              fill="#014FD9"
            />
            <path
              d="M109.73 206.752C109.73 219.592 99.322 230 86.4825 230C73.643 230 63.2345 219.592 63.2345 206.752C63.2345 193.913 73.643 183.504 86.4825 183.504C99.322 183.504 109.73 193.913 109.73 206.752Z"
              fill="#014FD9"
            />
            <path
              d="M46.496 143.518C46.496 156.357 36.0875 166.766 23.248 166.766C10.4085 166.766 0 156.357 0 143.518C0 130.678 10.4085 120.27 23.248 120.27C36.0875 120.27 46.496 130.678 46.496 143.518Z"
              fill="#014FD9"
            />
            <path
              d="M166.766 23.248C166.766 36.0875 156.357 46.496 143.518 46.496C130.678 46.496 120.27 36.0875 120.27 23.248C120.27 10.4085 130.678 0 143.518 0C156.357 0 166.766 10.4085 166.766 23.248Z"
              fill="#014FD9"
            />
            <path
              d="M162.116 86.4825C162.116 96.7541 153.789 105.081 143.518 105.081C133.246 105.081 124.919 96.7541 124.919 86.4825C124.919 76.2109 133.246 67.8841 143.518 67.8841C153.789 67.8841 162.116 76.2109 162.116 86.4825Z"
              fill="#014FD9"
            />
            <path
              d="M105.081 143.518C105.081 153.789 96.7541 162.116 86.4825 162.116C76.2109 162.116 67.8841 153.789 67.8841 143.518C67.8841 133.246 76.2109 124.919 86.4825 124.919C96.7541 124.919 105.081 133.246 105.081 143.518Z"
              fill="#014FD9"
            />
            <path
              d="M100.431 86.4825C100.431 94.1862 94.1862 100.431 86.4825 100.431C78.7788 100.431 72.5337 94.1862 72.5337 86.4825C72.5337 78.7788 78.7788 72.5337 86.4825 72.5337C94.1862 72.5337 100.431 78.7788 100.431 86.4825Z"
              fill="#014FD9"
            />
            <path
              d="M157.466 143.518C157.466 151.221 151.221 157.466 143.518 157.466C135.814 157.466 129.569 151.221 129.569 143.518C129.569 135.814 135.814 129.569 143.518 129.569C151.221 129.569 157.466 135.814 157.466 143.518Z"
              fill="#014FD9"
            />
            <path
              d="M32.5472 86.4825C32.5472 91.6183 28.3838 95.7817 23.248 95.7817C18.1122 95.7817 13.9488 91.6183 13.9488 86.4825C13.9488 81.3467 18.1122 77.1833 23.248 77.1833C28.3838 77.1833 32.5472 81.3467 32.5472 86.4825Z"
              fill="#014FD9"
            />
            <path
              d="M95.7817 23.248C95.7817 28.3838 91.6183 32.5472 86.4825 32.5472C81.3467 32.5472 77.1833 28.3838 77.1833 23.248C77.1833 18.1122 81.3467 13.9488 86.4825 13.9488C91.6183 13.9488 95.7817 18.1122 95.7817 23.248Z"
              fill="#014FD9"
            />
            <path
              d="M216.051 143.518C216.051 148.653 211.888 152.817 206.752 152.817C201.616 152.817 197.453 148.653 197.453 143.518C197.453 138.382 201.616 134.218 206.752 134.218C211.888 134.218 216.051 138.382 216.051 143.518Z"
              fill="#014FD9"
            />
            <path
              d="M152.817 206.752C152.817 211.888 148.653 216.051 143.518 216.051C138.382 216.051 134.218 211.888 134.218 206.752C134.218 201.616 138.382 197.453 143.518 197.453C148.653 197.453 152.817 201.616 152.817 206.752Z"
              fill="#014FD9"
            />
            <path d="M46.496 143.363H67.8841V143.673H46.496V143.363Z" fill="#014FD9" />
            <path d="M105.081 143.363H129.569V143.673H105.081V143.363Z" fill="#014FD9" />
            <path d="M157.466 143.363H197.453V143.673H157.466V143.363Z" fill="#014FD9" />
            <path d="M32.5472 86.3275H72.5337V86.6375H32.5472V86.3275Z" fill="#014FD9" />
            <path d="M100.431 86.3275H124.919V86.6375H100.431V86.3275Z" fill="#014FD9" />
            <path d="M162.116 86.3275H183.504V86.6375H162.116V86.3275Z" fill="#014FD9" />
            <path d="M109.73 206.597H134.218V206.907H109.73V206.597Z" fill="#014FD9" />
            <path d="M95.7817 23.093H120.27V23.403H95.7817V23.093Z" fill="#014FD9" />
            <path d="M143.363 46.496H143.673V67.8841H143.363V46.496Z" fill="#014FD9" />
            <path d="M143.363 105.081H143.673V129.569H143.363V105.081Z" fill="#014FD9" />
            <path d="M143.363 157.466H143.673V197.453H143.363V157.466Z" fill="#014FD9" />
            <path d="M86.3275 100.431H86.6375V124.919H86.3275V100.431Z" fill="#014FD9" />
            <path d="M86.3275 162.116H86.6375V183.504H86.3275V162.116Z" fill="#014FD9" />
            <path d="M86.3275 32.5472H86.6375V72.5337H86.3275V32.5472Z" fill="#014FD9" />
            <path d="M206.597 109.73H206.907V134.218H206.597V109.73Z" fill="#014FD9" />
            <path d="M23.093 95.7817H23.403V120.27H23.093V95.7817Z" fill="#014FD9" />
          </g>
        </svg>
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
        {/* {logo} */}
        {mode === 'full' ? (
          <Box
            ref={ref}
            component="div"
            sx={{
              width: 190,
              height: 40,
              display: 'inline-flex',
              ...sx,
            }}
            {...other}
          >
            <img src={LogoKemedisFull} alt="logo" />
          </Box>
        ) : (
          <Box
            ref={ref}
            component="div"
            sx={{
              width: 40,
              height: 40,
              display: 'inline-flex',
              ...sx,
            }}
            {...other}
          >
            <img src={KemedisLogoMini} alt="logo" />
          </Box>
        )}
      </Link>
    );
  }
);

export default Logo;
