import { ILabelValue } from 'src/@types/base';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/administrative';

const administrativeApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    getProvinces: build.query<ILabelValue[], unknown>({
      query: () => ({
        url: `${prefix}/provinces/labelValues/`,
        method: 'GET',
      }),
    }),
    getCities: build.query<ILabelValue[], string>({
      query: (provinceId) => ({
        url: `${prefix}/cities/labelValues/`,
        method: 'GET',
        params: { provinceId },
      }),
    }),
    getDistricts: build.query<ILabelValue[], string>({
      query: (cityId) => ({
        url: `${prefix}/district/labelValues/`,
        method: 'GET',
        params: { cityId },
      }),
    }),
    getSubDistricts: build.query<ILabelValue[], string>({
      query: (districtId) => ({
        url: `${prefix}/sub-district/labelValues/`,
        method: 'GET',
        params: { districtId },
      }),
    }),
  }),
});

export const {
  useGetProvincesQuery,
  useGetCitiesQuery,
  useGetDistrictsQuery,
  useGetSubDistrictsQuery,
} = administrativeApi;
