import { getProviderId } from 'src/utils/local-storage';
import { IPagination, IReqGlobalRsql } from 'src/@types/base';
import { IResPatient, IReqPatient } from 'src/@types/patient';
import { normalizeParams } from 'src/utils/convertFilterString';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/patients';

const patientApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    // /api/kemedis/patients/provider/{providerId}
    getPatientsByProviderId: build.query<IPagination<IResPatient>, Partial<IReqGlobalRsql>>({
      query: (reqRsql) => ({
        url: `${prefix}/provider/${getProviderId()}`,
        method: 'GET',
        params: normalizeParams(reqRsql),
      }),
    }),

    // /api/kemedis/patients/prepare/edit/{providerId}
    getPatientPrepareEdit: build.query<IResPatient, string>({
      query: (patientId) => ({
        url: `${prefix}/prepare/edit/${patientId}`,
        method: 'GET',
      }),
    }),

    // /api/kemedis/patients/idnumber/{idNumber}
    getPatientByIdNumber: build.query<IResPatient, string>({
      query: (idNumber) => ({
        url: `${prefix}/idnumber/${idNumber}`,
        method: 'GET',
      }),
    }),

    // /api/kemedis/patients
    createPatient: build.mutation<IResPatient, IReqPatient>({
      query: (patient) => ({
        url: `${prefix}`,
        method: 'POST',
        data: patient,
      }),
    }),

    // put /api/kemedis/patients/{id}
    updatePatient: build.mutation<IResPatient, IReqPatient>({
      query: (patient) => ({
        url: `${prefix}/${patient.id}`,
        method: 'PUT',
        data: patient,
      }),
    }),

    // get /api/kemedis/patients/{id}
    getPatientById: build.query<IResPatient, string>({
      query: (patientId) => ({
        url: `${prefix}/${patientId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetPatientsByProviderIdQuery,
  useCreatePatientMutation,
  useUpdatePatientMutation,
  useGetPatientByIdNumberQuery,
  useGetPatientByIdQuery,
  useGetPatientPrepareEditQuery,
} = patientApi;

patientApi.enhanceEndpoints({
  addTagTypes: ['Patient'],
  endpoints: {
    getPatientById: {
      providesTags: (result) => [{ type: 'Patient' as const, id: result?.id }],
    },
    getPatientsByProviderId: {
      providesTags: (result) =>
        result?.content?.map((patient) => ({ type: 'Patient' as const, id: patient.id })) ?? [],
    },
    updatePatient: {
      invalidatesTags: (result) => [{ type: 'Patient' as const, id: result?.id }],
    },
    createPatient: {
      invalidatesTags: (result) => [{ type: 'Patient' as const, id: result?.id }],
    },
  },
});
